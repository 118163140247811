import { axios } from '@/axios'
import Accordion from '@/components/accordion/accordion'
import { CurrenciesSelect } from '@/components/currencies-select/currencies-select'
import { Accounts, AccountsMeta, CurrencyItem } from '@/core/types'
import { CustomInput } from '@/UI/input/custom-input'
import { FormErrors } from 'apptimizm-ui'
import { computed, defineComponent, inject, onBeforeMount, onMounted, PropType, ref, Ref, watch } from 'vue'
import { TSidebarState } from '../../types'
import Swal from 'sweetalert2'

export default defineComponent({
  props: {
    onChangeState: {
      type: Function as PropType<(state: TSidebarState, id?: string) => void>,
      required: true
    },
    itemId: {
      type: [String, Object] as PropType<string|null>,
      required: true
    }
  },
  setup: function (props) {
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('settings-accounts-new'))
    const accountsMeta = new AccountsMeta()
    const item: Ref<Accounts> = ref(new Accounts())
    const isPersonalAccess: Ref<boolean> = ref(true)
    let currentEmail: string
    const currencies: Ref<Array<CurrencyItem>> = inject('currencies', ref([]))
    const acceptedUsersId: Ref<Array<any>> = ref([])
    const acceptedUsers: Ref<Array<any>> = ref([])
    const invitedUsers: Ref<Array<any>> = ref([])
    const usersToInvite: Ref<Array<any>> = ref([])
    const colors: Ref<Array<string>> = inject('colors', ref([]))
    const reloadAccounts = inject('reloadAccounts', () => {
    })
    const errors: Ref<FormErrors> = ref(new FormErrors({}))
    const sumbit = () => {
      const method = props.itemId ? 'patch' : 'post'
      const endpoint = props.itemId ? `${accountsMeta.endpoint}${props.itemId}/` : accountsMeta.endpoint
      axios[method](endpoint, accountsMeta.dump(item.value))
        .then(({ data }) => {
          axios.post(`${process.env.VUE_APP_API_HOST}` + `/bank_accounts/${data.id}/invite/`, { emails: usersToInvite.value })
            .then(({ data }) => {
              if (data.length) {
                Toast.fire({
                  icon: 'success',
                  title: 'Приглашения успешно отправлены'
                })
              }
            })
          if (data.errors) errors.value = new FormErrors(data.errors)
          else {
            props.onChangeState('settings-accounts-list')
            reloadAccounts()
          }
        })
    }

    const deleteAccount = () => {
      axios.delete(`${accountsMeta.endpoint}${props.itemId}/`)
        .then(({ data }) => {
          if (!data.errors) {
            props.onChangeState('settings-accounts-list')
            reloadAccounts()
          }
        })
    }

    const updateInvitedUsers = async () => {
      await axios.get(`${process.env.VUE_APP_API_HOST}` + `/bank_accounts/${item.value.id}/invitations/`)
        .then((data) => { invitedUsers.value = data.data })
        .then(() => { if (invitedUsers.value.length > 0 || acceptedUsers.value.length > 0) isPersonalAccess.value = false })
    }

    const disabled = computed(() => {
      if (!item.value.title) return true
      if (!item.value.currency) return true
      return false
    })

    const updateAccount = async () => {
      if (props.itemId) {
        await axios.get(`${accountsMeta.endpoint}${props.itemId}/`)
          .then(({ data }) => {
            item.value = accountsMeta.load(data)
          })
          .then(() => updateInvitedUsers())
          .then(() => getAcceptedUsers())
      } else {
        item.value.iconBackgroundColor = colors.value[0]
        item.value.currency = currencies.value[0]
      }
      if (JSON.parse(JSON.stringify(item.value.sharedUsers[0].length))) {
        isPersonalAccess.value = false
      }
    }

    onMounted(() => {
      updateAccount()
    })
    // const AccountIcon = {
    //   head: (): JSX.Element => (
    //     <div class='flex'>
    //       <div class='sidebar-accounts-color mr-1' style={{ backgroundColor: item.value.iconBackgroundColor }}/>
    //       <p>Иконка счета</p>
    //     </div>
    //   ),
    //   body: (): JSX.Element => (
    //     <div class='flex wrap'>
    //       { colors.value.map(color => <div
    //         class='sidebar-accounts-color mr-1 mb-1'
    //         style={{ backgroundColor: color, cursor: item.value.iconBackgroundColor === color ? 'default' : 'pointer' }}
    //         onClick={() => { item.value.iconBackgroundColor = color }}
    //       />)}
    //     </div>
    //   )
    // }
    const AccountColor = {
      head: (): JSX.Element => (
        <div class='flex'>
          <div class='sidebar-accounts-color mr-1' style={{ backgroundColor: item.value.iconBackgroundColor }}/>
          <p>Цвет счета</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='flex wrap'>
          {colors.value.map(color => <div
            class='sidebar-accounts-color mr-1 mb-1'
            style={{
              backgroundColor: color,
              cursor: item.value.iconBackgroundColor === color ? 'default' : 'pointer'
            }}
            onClick={() => {
              item.value.iconBackgroundColor = color
            }}
          />)}
        </div>
      )
    }

    const validateEmail = (email: string) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    }

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    })

    const onCloseSharing = () => {
      Swal.fire({
        title: 'Вы хотите закрыть доступ к публичному счету?',
        showCancelButton: true,
        confirmButtonText: 'Закрыть',
        cancelButtonText: 'Отмена',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.value.isShared) {
            return Toast.fire({
              icon: 'error',
              title: 'Невозможно выполнить данную операцию.'
            })
          }
          axios.post(`${process.env.VUE_APP_API_HOST}` + `https://wallet.test.apps.profinansy.ru/api/v1/bank_accounts/${item.value.id}/close_for_users/`, { delete_invitations: true })
          isPersonalAccess.value = true
        }
      })
    }

    const onDeletePopup = (user: any, userType: string, index?: number) => {
      Swal.fire({
        title: 'Вы хотите удалить пользователя из этого счета?',
        text: `${user.email}`,
        showCancelButton: true,
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отмена',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (userType === 'accepted') {
            item.value.sharedUsers[0].map(async (acceptedUser: any) => {
              const currentUser = JSON.parse(JSON.stringify(acceptedUser))
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (acceptedUsersId.value[index] === currentUser.master_id) {
                await axios.delete(`${process.env.VUE_APP_API_HOST}` + `/bank_accounts/${item.value.id}/shared_user/`, { data: { user_id: currentUser.id } })
                updateAccount()
              }
            })
          }
          if (userType === 'invited') {
            axios.delete(`${process.env.VUE_APP_API_HOST}` + `/bank_accounts/${item.value.id}/invitation/`, { data: { email: user.email } })
              // .catch((e) => {
              //   return Toast.fire({
              //     icon: 'error',
              //     title: e.response.data.errors
              //   })
              // })
              .then(() => updateInvitedUsers())
          }
        }
      })
    }

    const GeneralAccess = () => {
      return (
        <div>
          <p class='mt-2' style={{ color: '#898989' }}>Введите почту на которую отправить приглашение</p>
          <label class='custom-input mt-1'>
            <input
              placeholder='Напишите e-mail'
              value={currentEmail}
              onInput={(e: Event) => {
                currentEmail = (e.target as HTMLInputElement).value
              }}
            />
            <svg style={{ cursor: 'pointer' }}
              onClick={() => {
                if (validateEmail(currentEmail)) {
                  usersToInvite.value.push(currentEmail)
                  currentEmail = ''
                }
              }}
              width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="#E0E0E0"/>
            </svg>
          </label>
          {usersToInvite.value &&
                <div class='mt-2'>
                  <p style={{ color: '#898989', display: 'flex', alignItems: 'center' }}>Выбрано <div
                    class='shared-users-length'>{usersToInvite.value.length}</div></p>
                  <div class='shared-users-list'>
                    {usersToInvite.value.map((mail, index) => {
                      return (
                        <div class='shared-users-item'>
                          <div style={{ alignSelf: 'center' }}>{mail}</div>
                          <svg style={{ cursor: 'pointer' }} onClick={() => usersToInvite.value.splice(index, 1)}
                            width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                              fill="#E0E0E0"/>
                          </svg>
                        </div>
                      )
                    })}
                  </div>
                </div>
          }
        </div>
      )
    }

    const getAcceptedUsers = async () => {
      acceptedUsers.value = []
      acceptedUsersId.value = []
      await axios.get(`${process.env.VUE_APP_AUTH_SERVER}` + `/auth/info?user_key=${item.value.usersKey}`)
        .then((data) => {
          for (const masterId of Object.entries(data.data.data)) {
            acceptedUsersId.value.push(masterId[0])
          }
          for (const user of Object.entries(data.data.data)) {
            acceptedUsers.value.push(user[1])
          }
          acceptedUsersId.value.shift()
          acceptedUsers.value.shift()
        })
      console.log(acceptedUsers.value)
    }

    const AccessToggle = () => {
      if (isPersonalAccess.value) {
        return <p>При выборе режима “общий” появляется возможность предоставить доступ другому пользователю к вашему
          счёту. Например: жене, другу, коллеге. Это позволит вам контролировать ваши расходы в рамках данного счёта
          вместе.</p>
      }
      if (invitedUsers.value || acceptedUsers.value) {
        return (
          <div>
            {/* {acceptedUsers.map((user: any, index) => { */}
            {/*  return ( */}
            {/*    <div class='shared-users-item mt-2'> */}
            {/*      <div style={{ alignSelf: 'center' }}> */}
            {/*        <p style={{ color: '#383838', marginBottom: '2px' }}>{user.email}</p> */}
            {/*        <p style={{ color: '#33C927' }}>Есть доступ</p> */}
            {/*      </div> */}
            {/*      <svg style={{ cursor: 'pointer', height: 'auto' }} onClick={() => { */}
            {/*        onDeletePopup(user, 'accepted', index) */}
            {/*      }} */}
            {/*      width="24" height="24" viewBox="0 0 24 24" fill="none"> */}
            {/*        <path */}
            {/*          d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z" */}
            {/*          fill="#E0E0E0"/> */}
            {/*      </svg> */}
            {/*    </div> */}
            {/*  ) */}
            {/* })} */}
            {acceptedUsers.value &&
                acceptedUsers.value.map((user: any, index) => {
                  return (
                    <div class='shared-users-item mt-2'>
                      <div style={{ alignSelf: 'center' }}>
                        <p style={{ color: '#383838', marginBottom: '2px' }}>{user.email}</p>
                        <p style={{ color: '#33C927' }}>Есть доступ</p>
                      </div>
                      <svg style={{ cursor: 'pointer', height: 'auto' }} onClick={() => {
                        onDeletePopup(user, 'accepted', index)
                      }}
                      width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                          fill="#E0E0E0"/>
                      </svg>
                    </div>
                  )
                })
            }
            {invitedUsers.value.map((user) => {
              return (
                <div class='shared-users-item mt-2'>
                  <div style={{ alignSelf: 'center' }}>
                    <p style={{ color: '#383838', marginBottom: '2px' }}>{user.email}</p>
                    <p>Ожидает подтверждения</p>
                  </div>
                  <svg style={{ cursor: 'pointer', height: 'auto' }} onClick={() => {
                    onDeletePopup(user, 'invited')
                  }}
                  width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M13.4099 11.9999L19.7099 5.70994C19.8982 5.52164 20.004 5.26624 20.004 4.99994C20.004 4.73364 19.8982 4.47825 19.7099 4.28994C19.5216 4.10164 19.2662 3.99585 18.9999 3.99585C18.7336 3.99585 18.4782 4.10164 18.2899 4.28994L11.9999 10.5899L5.70994 4.28994C5.52164 4.10164 5.26624 3.99585 4.99994 3.99585C4.73364 3.99585 4.47824 4.10164 4.28994 4.28994C4.10164 4.47825 3.99585 4.73364 3.99585 4.99994C3.99585 5.26624 4.10164 5.52164 4.28994 5.70994L10.5899 11.9999L4.28994 18.2899C4.19621 18.3829 4.12182 18.4935 4.07105 18.6154C4.02028 18.7372 3.99414 18.8679 3.99414 18.9999C3.99414 19.132 4.02028 19.2627 4.07105 19.3845C4.12182 19.5064 4.19621 19.617 4.28994 19.7099C4.3829 19.8037 4.4935 19.8781 4.61536 19.9288C4.73722 19.9796 4.86793 20.0057 4.99994 20.0057C5.13195 20.0057 5.26266 19.9796 5.38452 19.9288C5.50638 19.8781 5.61698 19.8037 5.70994 19.7099L11.9999 13.4099L18.2899 19.7099C18.3829 19.8037 18.4935 19.8781 18.6154 19.9288C18.7372 19.9796 18.8679 20.0057 18.9999 20.0057C19.132 20.0057 19.2627 19.9796 19.3845 19.9288C19.5064 19.8781 19.617 19.8037 19.7099 19.7099C19.8037 19.617 19.8781 19.5064 19.9288 19.3845C19.9796 19.2627 20.0057 19.132 20.0057 18.9999C20.0057 18.8679 19.9796 18.7372 19.9288 18.6154C19.8781 18.4935 19.8037 18.3829 19.7099 18.2899L13.4099 11.9999Z"
                      fill="#E0E0E0"/>
                  </svg>
                </div>
              )
            })}
            {!item.value.isShared && <GeneralAccess/>}
          </div>
        )
      }
      return (
        <GeneralAccess/>
      )
    }

    const AccountSharing = {
      head: (): JSX.Element => (
        <div class='flex'>
          <svg class='mr-1' width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path
              d="M6.50351 10.2C6.50351 11 6.70351 11.8 7.00351 12.5H2.90351C2.30351 12.5 1.70351 12.2 1.40351 11.7C1.10351 11.1 1.00351 10.5 1.20351 10C1.60351 9 2.40351 8.2 3.30351 7.7C2.90351 7.2 2.60351 6.5 2.60351 5.8C2.60351 4.3 3.90351 3 5.40351 3C7.00351 3 8.20351 4.3 8.20351 5.8C8.20351 5.9 8.20351 6.1 8.20351 6.2C7.20351 7.2 6.50351 8.6 6.50351 10.2Z"
              fill={item.value.iconBackgroundColor}/>
            <path
              d="M22.8035 11.5999C22.5035 12.0999 21.9035 12.3999 21.3035 12.3999H17.2035C17.5035 11.6999 17.7035 10.8999 17.7035 10.0999C17.7035 8.4999 17.1035 7.0999 16.0035 6.0999C16.0035 5.9999 16.0035 5.7999 16.0035 5.6999C16.0035 4.0999 17.3035 2.8999 18.8035 2.8999C20.3035 2.8999 21.6035 4.1999 21.6035 5.6999C21.6035 6.3999 21.3035 7.0999 20.9035 7.5999C21.8035 8.1999 22.5035 8.9999 23.0035 9.9999C23.2035 10.4999 23.1035 11.0999 22.8035 11.5999Z"
              fill={item.value.iconBackgroundColor}/>
            <path
              d="M14.5035 13.4001C15.5035 12.7001 16.2035 11.5001 16.2035 10.1001C16.2035 7.9001 14.4035 6.1001 12.2035 6.1001C10.0035 6.1001 8.10352 8.0001 8.10352 10.2001C8.10352 11.5001 8.80352 12.7001 9.80352 13.5001C8.00352 14.1001 6.60352 15.5001 5.80352 17.3001C5.50352 18.0001 5.60352 18.8001 6.00352 19.5001C6.50352 20.2001 7.20352 20.6001 8.00352 20.6001H16.2035C17.0035 20.6001 17.8035 20.2001 18.2035 19.5001C18.6035 18.8001 18.7035 18.0001 18.4035 17.3001C17.7035 15.4001 16.2035 14.1001 14.5035 13.4001Z"
              fill={item.value.iconBackgroundColor}/>
          </svg>
          <p>Доступ</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='sidebar-access-container'>
          <div class='sidebar-buttongroup'>
            <div
              class={isPersonalAccess.value ? 'sidebar-buttongroup-btn sidebar-buttongroup-btn-active' : 'sidebar-buttongroup-btn'}
              onClick={() => onCloseSharing()}>Личный
            </div>
            <div
              class={isPersonalAccess.value ? 'sidebar-buttongroup-btn' : 'sidebar-buttongroup-btn sidebar-buttongroup-btn-active'}
              onClick={() => {
                isPersonalAccess.value = false
              }}>Общий
            </div>
          </div>
          <div class='sidebar-access-body'>
            <AccessToggle/>
          </div>
        </div>
      )
    }

    return () => <div class='sidebar-accounts sidebar-extension-block sidebar-border-left'>
      <div class='sidebar-cell shadow head flex-sb'>
        <h4>{sidebarState.value === 'settings-accounts-new' ? 'Новый счет' : 'Редактирование счета'}</h4>
        {sidebarState.value === 'settings-accounts-edit' && <button onClick={deleteAccount}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.525 21C6.125 21 5.775 20.85 5.475 20.55C5.175 20.25 5.025 19.9 5.025 19.5V5.25H4V3.75H8.7V3H15.3V3.75H20V5.25H18.975V19.5C18.975 19.9 18.825 20.25 18.525 20.55C18.225 20.85 17.875 21 17.475 21H6.525ZM17.475 5.25H6.525V19.5H17.475V5.25ZM9.175 17.35H10.675V7.375H9.175V17.35ZM13.325 17.35H14.825V7.375H13.325V17.35ZM6.525 5.25V19.5V5.25Z"
              fill="#FF6464"/>
          </svg>
        </button>}
      </div>
      <div style={{ overflowY: 'scroll', maxHeight: '90%' }}>
        <div class='sidebar-cell with-dashed-line'>
          <CustomInput
            modelValue={item.value.title}
            placeholder='Наименование счета'
            onClearValue={() => {
              item.value.title = ''
            }}
            onValueChange={(v: string) => {
              item.value.title = v
            }}
            errors={errors.value.forField('title')}
          />
        </div>
        <div class='sidebar-cell with-dashed-line'>
          <CurrenciesSelect
            items={currencies.value}
            modelValue={item.value.currency}
            onValueChange={(v: CurrencyItem) => {
              item.value.currency = v
            }}
            errors={errors.value.forField('currency')}
          />
        </div>
        {/* <div class='sidebar-cell with-dashed-line'> */}
        {/*  <Accordion */}
        {/*    slots={AccountIcon} */}
        {/*  /> */}
        {/*  { errors.value.forField('icon_background_color') && <div class='error-text text mt-1'>{errors.value.forField('icon_background_color')}</div> } */}
        {/* </div> */}
        <div class='sidebar-cell with-dashed-line'>
          <Accordion
            slots={AccountColor}
          />
          {errors.value.forField('icon_background_color') &&
              <div class='error-text text mt-1'>{errors.value.forField('icon_background_color')}</div>}
        </div>
        <div class='sidebar-cell with-dashed-line'>
          <Accordion
            slots={AccountSharing}
          />
          {errors.value.forField('icon_background_color') &&
              <div class='error-text text mt-1'>{errors.value.forField('icon_background_color')}</div>}
        </div>
        <div class='sidebar-cell'>
          <div class='primary-grid-2'>
            <button class='default-button full-width'
              onClick={() => props.onChangeState('settings-accounts-list')}>Отменить
            </button>
            <button class='primary-button full-width' disabled={disabled.value} onClick={sumbit}>Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  }
})
