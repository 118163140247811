import { useColorFilter } from '@/composables/use-color-filter'
import { Transaction } from '@/core/types'
import { defineComponent, PropType, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Transaction>,
      required: true
    },
    onSetTransactionId: {
      type: Function as PropType<(id: string) => void>,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const total = computed(() => {
      return store.state.total
    })

    return () => <div class='sidebar-transaction-card flex-sb' onClick={() => props.onSetTransactionId(props.item.id)} >
      <div class='flex'>
        <div class='sidebar-transactions-icon-wrapper'>
          <div
            class='main-icon flex-center'
          >
            <div class='main-icon-bg' style={{ backgroundColor: props.item.categorySetting.backgroundColor, opacity: '12%' }}/>
            { props.item.categorySetting.icon.file
              ? <img style={useColorFilter(props.item.categorySetting.backgroundColor)} src={props.item.categorySetting.icon.file} alt='' />
              : <img src={require(`@/assets/img/default-${props.item.categorySetting.category.value}-icon.svg`)} alt=''/>
            }
          </div>
          <div
            class='second-icon flex-center'
            style={{ backgroundColor: props.item.expensesType.backgroundColor }}
          >
            <img
              src={props.item.expensesType.icon.file}
              alt=''
              style={useColorFilter('#fff')}
            />
          </div>
        </div>
        <div>
          <p>{ props.item.expensesType.title }</p>
          <p class='text'>{ props.item.categorySetting.category.name }</p>
        </div>
      </div>
      <div class="sidebar-transaction-card-right">
        <div class="sidebar-transaction-card-amount mb-1">{new Intl.NumberFormat('ru-RU').format(Number(props.item.amount))} {props.item.bankAccont.currency.symbol || props.item.bankAccont.currency.code}</div>
        <div class="card-graph-line">
          <div
            class="card-graph-line-value"
            style={{
              width: total.value > 0 ? Number(props.item.amount) / total.value * 100 + '%' : '100%',
              background: props.item.expensesType.backgroundColor
            }}
          ></div>
        </div>
      </div>
    </div>
  }
})
