import { axios } from '@/axios'
import Accordion from '@/components/accordion/accordion'
import { useColorFilter } from '@/composables/use-color-filter'
import { useObserver } from '@/composables/use-observer'
import { CategorySetting, CategorySettingMeta, ExpensesType, ExpensesTypeMeta, Icon } from '@/core/types'
import usePaginatedBackend from 'apptimizm-ui/src/composables/use-paginated-api'
import { defineComponent, inject, onBeforeMount, PropType, ref, Ref } from 'vue'
import { TSidebarState } from '../../types'

export default defineComponent({
  props: {
    itemId: {
      type: String,
      required: true
    },
    onChangeState: {
      type: Function as PropType<(state: TSidebarState) => void>,
      required: true
    },
    onCreatingExpensesType: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup (props) {
    const target = ref(null) as unknown as Ref<HTMLElement>
    const item: Ref<CategorySetting> = ref(new CategorySetting())
    const colors: Ref<string[]> = inject('colors', ref([]))
    const icons: Ref<Array<Icon>> = ref([])
    const reloadCategorySettings = inject('reloadCategorySettings', () => {})
    const reloadTransactions = inject('reloadTransactions', () => {})
    const params = { category: '' }
    const {
      items: expensesTypes,
      reload,
      loadNext
    } = usePaginatedBackend(
      new ExpensesTypeMeta().endpoint,
      axios,
      ref(params),
      ref(10),
      'page',
      (x) => new ExpensesTypeMeta().load(x),
      true,
      'results',
      'count'
    )

    onBeforeMount(() => {
      axios.get(`${new CategorySettingMeta().endpoint}${props.itemId}/`)
        .then(({ data }) => {
          item.value = new CategorySettingMeta().load(data)
          params.category = item.value.category.value
          reload()
        })

      axios.get('/file/icon/', { params: { type: 'category' } })
        .then(({ data }) => { icons.value = data.results })
    })

    useObserver(loadNext, target)

    const sumbit = () => {
      axios.patch(`${new CategorySettingMeta().endpoint}${props.itemId}/`, new CategorySettingMeta().dump(item.value))
        .then(({ data }) => {
          if (data.errors) return
          props.onChangeState('settings-category-list')
          reloadCategorySettings()
          reloadTransactions()
        })
    }

    const deleteExpensesType = (id: string) => {
      axios.delete(new ExpensesTypeMeta().endpoint + id + '/')
        .then(({ status }) => {
          if (status === 204) reload()
        })
    }

    const CategoryIcon = {
      head: (): JSX.Element => (
        <div class='flex' style={{ alignItems: 'center' }}>
          <div class='sidebar-category-icon mr-2'>
            <img src={item.value.icon.file} alt='' style={useColorFilter(item.value.backgroundColor)}/>
          </div>
          <p>Иконка категории</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='sidebar-category-icons-list'>
          { icons.value.map(icon => <div
            class={{ 'sidebar-category-icon flex-center': true, active: icon.id === item.value.icon.id }}
            onClick={() => { item.value.icon = icon }}
          >
            <img src={icon.file} alt='' />
          </div>)}
        </div>
      )
    }

    const CategoryColor = {
      head: (): JSX.Element => (
        <div class='flex wrap'>
          <div class='sidebar-accounts-color mr-2 mb-1' style={{ backgroundColor: item.value.backgroundColor }}/>
          <p>Цвет категории</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='flex wrap'>
          { colors.value.map(color => <div
            class='sidebar-accounts-color mr-1 mb-1'
            style={{ backgroundColor: color, cursor: item.value.backgroundColor === color ? 'default' : 'pointer' }}
            onClick={() => { item.value.backgroundColor = color }}
          />)}
        </div>
      )
    }

    return () => <div class='sidebar-category-item sidebar-extension-block sidebar-border-left'>
      <div class='sidebar-cell shadow head'>
        <p class='text secondary-color'>Категория</p>
        <h4>{ item.value.category.name }</h4>
      </div>
      <div class='sidebar-scroll-container scroll-bar'>
        <div class='sidebar-cell'>
          <Accordion slots={CategoryIcon}/>
        </div>
        <div class='sidebar-cell'>
          <Accordion slots={CategoryColor}/>
        </div>
        <div class='sidebar-cell'>
          <div class='inner-container'>
            <p class='title desc mb-1'>Тип расхода в категории</p>
            <div class='sidebar-scroll-container mb-2 scroll-bar'>
              <div class='expenses-types-list'>
                { expensesTypes.value.map((item: ExpensesType) => <div class='expenses-types-item flex-sb'>
                  <div class='flex'>
                    <div class='expenses-types-item-icon mr-1 flex-center' style={{ backgroundColor: item.backgroundColor }}>
                      <img src={item.icon.file} alt='' style={useColorFilter('#ffffff')}/>
                    </div>
                    <p>{ item.title }</p>
                  </div>
                  <button class='expenses-types-delete' onClick={() => {
                    if (item.canDeleted) deleteExpensesType(item.id)
                  }}>
                    <img style={!item.canDeleted ? `${useColorFilter('#898989')}; cursor: default` : ''} src={require('@/assets/img/delete-icon.svg')} alt='' />
                  </button>
                </div>) }
              </div>
              <div ref={target}/>
            </div>
            <button class='link flex' onClick={props.onCreatingExpensesType}>
              <span class='mr-1'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 1C9.75 0.585786 9.41421 0.25 9 0.25C8.58579 0.25 8.25 0.585786 8.25 1V8.25H1C0.585787 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585787 9.75 1 9.75H8.25V17C8.25 17.4142 8.58579 17.75 9 17.75C9.41421 17.75 9.75 17.4142 9.75 17V9.75H17C17.4142 9.75 17.75 9.41421 17.75 9C17.75 8.58579 17.4142 8.25 17 8.25H9.75V1Z" fill="#6553F5"/>
                </svg>
              </span>
              <span>Добавить</span>
            </button>
          </div>
        </div>
      </div>
      <div class='sidebar-cell'>
        <div class='primary-grid-2'>
          <button class='default-button full-width' onClick={() => props.onChangeState('settings-category-list')}>Отменить</button>
          <button class='primary-button full-width' onClick={sumbit}>Сохранить</button>
        </div>
      </div>
    </div>
  }
})
