import './segment-diagram.sass'
import { defineComponent, PropType, ref, Ref } from 'vue'
// import cn from 'classnames'
import { Segment, Info } from './types'
import { useToggle } from '@/composables/use-toggle'

export default defineComponent({
  props: {
    indicatorInfo: {
      type: Object as PropType<Info>,
      default: () => new Info()
    },
    titleFirstContent: {
      type: Function as PropType<() => JSX.Element>
    },
    isTitleFirstContent: {
      type: Boolean,
      default: false
    },
    indicatorData: {
      type: Array as PropType<Segment[]>,
      required: true
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => ['#64D0DA']
    },
    size: {
      type: Number,
      default: 220
    },
    strokeWidth: {
      type: Number,
      default: 44
    }
  },
  setup (props) {
    const svgContainer = ref(null)
    const showModal: Ref<boolean> = ref(false)
    let selectedItem = { name: '', value: 0, id: '', sum: 0 }
    return () => {
      const data = props.indicatorData
      const info = props.indicatorInfo
      const size = props.size
      const strokeWidth = props.strokeWidth
      const r = size / 2 - strokeWidth / 2
      const circleLength = 2 * Math.PI * r
      let segmentsLength = 0
      return (
        <div class='segment-diagram-wrap' style={{ width: `${size}px` }}>
          <div class='segment-diagram' style={`height: ${size}px;`}>
            <div
              class='text-in-circle-diagram'
              style={`
                top: ${strokeWidth + 2}px;
                left: ${strokeWidth + 2}px;
                width: ${size - strokeWidth * 2}px;
                height: ${size - strokeWidth * 2}px;
              `}
            >
              <div class='first-line-circle-diagram'>{props.isTitleFirstContent && props?.titleFirstContent ? props?.titleFirstContent() : info.titleFirst}</div>
              <div class='second-line-circle-diagram'>{info.total === 0 ? 0 : info.titleSecond}</div>
            </div>

            <style>
              {`.segment-diagram-wrap .sector-diagram-figure .one-sector-diagram:hover {
                stroke-width: ${strokeWidth + 4}px;
              }`}
            </style>

            <div ref={svgContainer}>
              <svg width='100%' height='100%' viewBox={`0 0 ${size + 4} ${size + 4}`} class='sector-diagram-figure'>

                {info.total === 0 && <circle
                  class='one-sector-diagram animations default-circle disabled'
                  pointer-events='visibleStroke'
                  stroke-dasharray='100 0'
                  cx={size / 2 + 2}
                  cy={size / 2 + 2}
                  r={r}
                  fill='transparent'
                  stroke='#E0E0E0'
                  stroke-width={strokeWidth}
                  stroke-dashoffset={circleLength}
                />}

                {data.map((item: Segment, idx: number) => {
                  const count = item.value
                  const percent = count / info.total * 100 || 0
                  const segmentLength = circleLength / 100 * percent
                  const strokeDasharray = `${segmentLength} ${circleLength - segmentLength}`
                  const offset = segmentsLength
                  const strokeDashoffset = 0.2 * circleLength - offset
                  segmentsLength += segmentLength
                  // eslint-disable-next-line
                  const handleOpen = (item: any) => {
                    selectedItem = item
                    useToggle(showModal)
                  }
                  const handleClose = () => {
                    useToggle(showModal)
                    selectedItem = { name: '', value: 0, id: '', sum: 0 }
                  }
                  return (
                    <g onMouseover={() => handleOpen(item)} onMouseleave={() => handleClose()}>
                      <circle
                        class='one-sector-diagram animations'
                        pointer-events='visibleStroke'
                        stroke-dasharray={strokeDasharray}
                        cx={size / 2 + 2}
                        cy={size / 2 + 2}
                        r={r}
                        fill='transparent'
                        stroke={props.colors[idx]}
                        stroke-width={strokeWidth}
                        stroke-dashoffset={strokeDashoffset}
                      />
                    </g>
                  )
                })}
              </svg>
              {showModal.value && <div class="segment-diagram-tooltip">
                <div class="segment-diagram-tooltip-inner">
                  <div class="flex flex-ai-fs">
                    <div>{selectedItem.sum}</div>
                    <div class={`difference ${selectedItem.value >= 0 ? 'success' : 'warning'}`}>{selectedItem.value}%</div>
                  </div>
                  <small>{selectedItem.name}</small>
                </div>
              </div>}
            </div>
          </div>
        </div>
      )
    }
  }
})
