import { axios } from '@/axios'
import AccountsComponent from '@/components/accounts/accounts'
import Sidebar from '@/components/sidebar/sidebar'
import Chart from '@/components/charts/chart/box'
import Tabs from '@/components/tabs/tabs'
import { TSidebarState } from '@/components/sidebar/types'
import HistogramCategories from '@/components/charts/histogram/box-categories'
import HistogramExpensesName from '@/components/charts/histogram/box-expenses-name'
import HistogramExpensesType from '@/components/charts/histogram/box-expenses-type'
import { Accounts, AccountsMeta, BaseAccount, CategorySettingMeta, Constance, ListItem, TransactionMeta } from '@/core/types'
import { usePaginatedApi } from 'apptimizm-ui'
import { defineComponent, onBeforeMount, provide, Ref, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment, { Moment } from 'moment'
import Swal from 'sweetalert2'

moment.locale('ru')

const typeTabs = [
  { name: 'Категории', value: 'categories' },
  { name: 'Тип расхода', value: 'expenses-type' },
  { name: 'Расход', value: 'expenses' }
]

const periodTabs: Array<ListItem> = [
  { name: 'Неделя', value: 'current_week' },
  { name: '2 Недели', value: 'two_weeks_ago' },
  { name: 'Месяц', value: 'current_month' },
  { name: 'Год', value: 'current_year' }
]

const mobileTabs: Array<ListItem> = [
  { name: 'Аналитика', value: 'analitics' },
  { name: 'Мои расходы', value: 'expenses' }
]

export default defineComponent({
  setup () {
    const sidebarState: Ref<TSidebarState> = ref('default')
    const accountsMeta = new AccountsMeta()
    const categorySettingMeta = new CategorySettingMeta()
    const transactionMeta = new TransactionMeta()
    const selectedAccount: Ref<Accounts> = ref(BaseAccount)
    const transactionsParams: { [key: string]: string | string[] } = {}
    const { items: accounts, reload: reloadAccounts, loadNext: loadNextAccount } = usePaginatedApi(accountsMeta.endpoint, axios, ref({}), ref(20), 'page', (x) => accountsMeta.load(x), true, 'results', 'count')
    const { items: categorySetting, reload: reloadCategorySetting } = usePaginatedApi(categorySettingMeta.endpoint, axios, ref({}), ref(20), 'page', (x) => categorySettingMeta.load(x), true, 'results', 'count')
    const { items: transactions, reload: reloadTransactions, loadNext: loadNextTransactions, isLoading: isLoadingTransactions } = usePaginatedApi(transactionMeta.endpoint, axios, ref(transactionsParams), ref(20), 'page', (x) => transactionMeta.load(x), true, 'results', 'count')
    const currencies: Ref<Array<ListItem>> = ref([])
    const colors: Ref<Array<string>> = ref([])
    const isMobile: Ref<boolean> = ref(false)
    const onChangeState = (state: TSidebarState) => {
      mobileTab.value = 'expenses'
      sidebarState.value = state
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

    const onChangeSelectedAccount = (value: Accounts) => {
      selectedAccount.value = value
      if (value.id) transactionsParams.bank_account = selectedAccount.value.id
      else delete transactionsParams.bank_account

      reloadTransactions()
    }

    const getWindowSize = () => {
      if (!window) return
      isMobile.value = window.innerWidth < 769
    }

    provide('accounts', accounts)
    provide('currencies', currencies)
    provide('colors', colors)
    provide('reloadAccounts', reloadAccounts)
    provide('loadNextAccount', loadNextAccount)
    provide('sidebarState', sidebarState)
    provide('onChangeState', onChangeState)
    provide('selectedAccount', selectedAccount)
    provide('onChangeSelectedAccount', onChangeSelectedAccount)
    provide('categorySettings', categorySetting)
    provide('reloadCategorySettings', reloadCategorySetting)
    provide('transactions', transactions)
    provide('reloadTransactions', reloadTransactions)
    provide('loadNextTransactions', loadNextTransactions)
    provide('isMobile', isMobile)
    provide('isLoadingTransactions', isLoadingTransactions)

    const currentTab = ref('categories')
    const mobileTab = ref(mobileTabs[0].value)

    const paymentEnd: Ref<Moment | null> = ref(moment())
    const paymentStart: Ref<Moment | null> = ref(moment().subtract(1, 'week'))
    const period = ref('current_week')

    const defaultFilter = computed(() => {
      const result: {[key: string]: string } = {}

      if (paymentStart.value) result.payment_start = paymentStart.value.format('YYYY-MM-DD')
      if (paymentEnd.value) result.payment_end = paymentEnd.value.format('YYYY-MM-DD')
      if (selectedAccount.value.id) result.bank_account = selectedAccount.value.id

      return result
    })

    onBeforeMount(() => {
      getWindowSize()
      reloadAccounts()
      reloadCategorySetting()
      reloadTransactions()
      axios.get(accountsMeta.endpoint + 'currencies/')
        .then(({ data }) => { currencies.value = data })
      axios.get('/constance/').then(({ data }) => {
        colors.value = (data as Constance[]).filter(x => x.key === 'COLORS')[0].value
      })
    })

    onMounted(() => {
      window.addEventListener('resize', getWindowSize)
      window.addEventListener('updateAnalitics', () => { selectedAccount.value = { ...selectedAccount.value } })
      axios.get(`${process.env.VUE_APP_API_HOST}/bank_accounts/user/invitations/`)
        .then((data) => {
          data.data.map((notification: any) => {
            invitationPopup(notification)
          })
        })
    })

    const setPaymentStart = () => {
      clearDate()
      let result = moment()
      if (period.value === 'current_week') result = moment().subtract(1, 'week')
      if (period.value === 'two_weeks_ago') result = moment().subtract(2, 'week')
      if (period.value === 'current_month') result = moment().subtract(1, 'month')
      if (period.value === 'current_year') result = moment().subtract(1, 'year')
      paymentStart.value = result
    }

    const store = useStore()
    const clearDate = () => store.commit('setDate', null)

    const invitationPopup = (notification: any) => {
      axios.get(`${process.env.VUE_APP_AUTH_SERVER}` + `/auth/info?user_key=${notification.bank_account.user_key}`)
        .then((data) => {
          console.log(notification.bank_account.title)
          Swal.fire({
            title: 'Приглашение',
            text: `${data.data.data[notification.bank_account.master_id].email} предоставляет вам доступ к своему счету '${notification.bank_account.title}'`,
            showCancelButton: true,
            confirmButtonText: 'Принять',
            cancelButtonText: 'Отклонить'
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post('https://wallet.test.apps.profinansy.ru/api/v1/bank_accounts/invitation/accept/', { invitation_id: notification.id })
              reloadAccounts()
            } else {
              axios.post('https://wallet.test.apps.profinansy.ru/api/v1/bank_accounts/invitation/reject/', { invitation_id: notification.id })
            }
          })
        })
    }

    return () => <div class='layout'>
      <div class='content'>
        <AccountsComponent
          items={accounts.value}
          selectedAccount={selectedAccount.value}
        />
        { isMobile.value && <Tabs
          tabs={mobileTabs}
          onChangeTab={({ value }) => {
            if (value === 'expenses') onChangeState('default')
            else mobileTab.value = value
          }}
          selectedTab={mobileTab.value}
          isMobile={true}
        /> }
        {(!isMobile.value || mobileTab.value === 'analitics') && <div>
          <div class='screen analitics-tabs flex-sb mt-2 mb-2'>
            <Tabs
              tabs={typeTabs}
              selectedTab={currentTab.value}
              onChangeTab={({ value }) => {
                currentTab.value = value
                clearDate()
              }}
            />
          </div>
          <Chart
            defaultFilter={defaultFilter.value}
            isYear={period.value === 'current_year'}
          />
          {currentTab.value === 'categories' &&
            <HistogramCategories
              defaultFilter={defaultFilter.value}
              isYear={period.value === 'current_year'}
            />}
          {currentTab.value === 'expenses-type' &&
            <HistogramExpensesType
              defaultFilter={defaultFilter.value}
              isYear={period.value === 'current_year'}
            />
          }
          {currentTab.value === 'expenses' &&
            <HistogramExpensesName
              defaultFilter={defaultFilter.value}
              isYear={period.value === 'current_year'}
            />
          }
        </div>}
        <div class='mb-2'/>
      </div>
      {(!isMobile.value || mobileTab.value === 'expenses') && <Sidebar>
        <Tabs
          tabs={periodTabs}
          selectedTab={period.value}
          onChangeTab={({ value }) => {
            period.value = value
            setPaymentStart()
          }}
        />
      </Sidebar>}
    </div>
  }
})
