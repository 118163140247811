import './style.sass'

interface RadioButtonProps {
  modelValue: boolean
  placeholder?: string
  onValueChange: () => void
}

export const RadioButton = ({ modelValue, placeholder, onValueChange }: RadioButtonProps): JSX.Element => (
  <div class={{ 'custom-radio-button': true, active: modelValue }} onClick={onValueChange}>
    <div class='custom-radio-button-button'>
      <div class='custom-radio-button-indicator'/>
    </div>
    {placeholder && <span class='custom-radio-button-placeholder ml-1'>{placeholder}</span>}
  </div>
)
