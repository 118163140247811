import { Entity, EntityMeta } from 'apptimizm-ui'

export class Info extends Entity {
  titleFirst: string = ''
  titleSecond: string = ''
  total: number = 0
}

export class InfoMeta extends EntityMeta<Info> {
  entity = Info

  fields = {
    id: { type: String },
    titleFirst: { type: String },
    titleSecond: { type: String },
    total: { type: Number }
  }
}

export class Segment extends Entity {
  name: string = ''
  value: number = 0
}

export class SegmentMeta extends EntityMeta<Segment> {
  entity = Segment

  fields = {
    id: { type: String },
    name: { type: String },
    value: { type: Number }
  }
}

export class SegmentFull extends Entity {
  info: Info = new Info()
  data: Segment[] = []
}

export class SegmentFullMeta extends EntityMeta<SegmentFull> {
  entity = SegmentFull

  fields = {
    id: { type: String },
    info: { type: String },
    data: { type: Number }
  }
}
