import './histogram.sass'
import { defineComponent, ref, Ref, PropType, onBeforeMount, watch } from 'vue'
import HistogramGenerate from './graphic-histogram-generate-expenses-name'
import SegmentDiagramGenerate from '../segment-diagram/segment-diagram-generate-expenses-name'
import { TransactionAnalyticsByExpensesName, TransactionAnalyticsByExpensesNameMeta, Expense } from '../types'
import { useStore } from 'vuex'
import { axios } from '@/axios'

export default defineComponent({
  props: {
    defaultFilter: {
      type: Object as PropType<{[key: string]: string }>,
      required: true
    },
    isYear: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const transactionAnalyticsByExpensesName: Ref<TransactionAnalyticsByExpensesName[]> = ref([])

    onBeforeMount(() => {
      load()
    })

    watch(() => props.defaultFilter, () => {
      load()
    })

    const load = async () => {
      await getTransactionAnalytics()
    }

    const getTransactionAnalytics = async () => {
      const params = { ...props.defaultFilter }
      if (params.date) delete params.date

      const result = await axios.get(new TransactionAnalyticsByExpensesNameMeta().endpoint, { params })
      transactionAnalyticsByExpensesName.value = result.data.map((i: any) => new TransactionAnalyticsByExpensesNameMeta().load(i))
      setExpenses(transactionAnalyticsByExpensesName.value)
    }

    const store = useStore()
    const setExpenses = (data: TransactionAnalyticsByExpensesName[]) => {
      const expenses: Expense[] = []
      data.forEach(i => expenses.push(...i.expenses))
      store.commit('setExpenses', expenses)
    }

    return () => transactionAnalyticsByExpensesName.value.length
      ? <div class="histogram">
        <div class="box-white mb-2">
          <HistogramGenerate items={transactionAnalyticsByExpensesName.value} isYear={props.isYear}/>
        </div>
        <div class="box-white segment-diagram-big mb-2">
          <SegmentDiagramGenerate items={transactionAnalyticsByExpensesName.value} />
        </div>
      </div>
      : <p>Нет расходов за данный период</p>
  }
})
