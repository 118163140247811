import { Entity, EntityMeta } from 'apptimizm-ui'
import {
  CategorySetting,
  CategorySettingMeta,
  ExpensesName,
  ExpensesNameMeta,
  ExpensesType,
  ExpensesTypeMeta
} from '@/core/types'

type GraphicDiagramData = {
  xAxisLabels: string[],
  yAxisLabels: Array<{ label: string, value: number }>,
  lines: Array<{
    name: string,
    type: string,
    color: string,
    points: any[]
  }>
}

type IGraphicDiagram = {
  data: string,
  title: string
}

type StyleLine = {
  strokeDasharray: string,
  strokeWidth: number,
  gradient: string,
  legendType: boolean
}

export class Line extends Entity {
  name: string = ''
  type: string = 'line'
  color: string = ''
  points: number[] = []
}

export class LineMeta extends EntityMeta<Line> {
  entity = Line

  fields = {
    id: { type: String, backendKey: 'name' },
    name: { type: String },
    type: { type: String },
    color: { type: String },
    points: { type: (i: number[]) => i }
  }
}

export class RAxisLabel extends Entity {
  label: string = ''
  value: number = 0
}

export class RAxisLabelMeta extends EntityMeta<RAxisLabel> {
  entity = RAxisLabel

  fields = {
    id: { type: String },
    label: { type: String },
    value: { type: Number }
  }
}

export class PolarDiagramData extends Entity {
  tAxisLabels: string[] = []
  rAxisLabels: RAxisLabel[] = []
  lines: Line[] = []
}

export class PolarDiagramDataMeta extends EntityMeta<PolarDiagramData> {
  entity = PolarDiagramData

  fields = {
    tAxisLabels: { type: (i: string[]) => i },
    rAxisLabels: { type: (i: RAxisLabel[]) => i },
    lines: { type: (i: Line[]) => i }
  }
}

export class TransactionAnalytics extends Entity {
  date: number = 0
  sum: number = 0
}

export class TransactionAnalyticsMeta extends EntityMeta<TransactionAnalytics> {
  entity = TransactionAnalytics
  endpoint = '/transaction/analytics/by_date/'

  fields = {
    id: { type: String, backendKey: 'hour' },
    date: { type: Number, backendKey: 'hour' },
    sum: { type: Number, backendKey: 'total_amount' }
  }
}

export class TransactionTotalAmount extends Entity {
  totalAmount: number = 0
  percent: number = 0
}

export class TransactionTotalAmountMeta extends EntityMeta<TransactionTotalAmount> {
  entity = TransactionTotalAmount
  endpoint = '/transaction/total_amount/'

  fields = {
    totalAmount: { type: Number, backendKey: 'total_amount' },
    percent: { type: Number }
  }
}

export class Expense extends Entity {
  date: string = ''
  totalAmount: number = 0
}

export class ExpenseMeta extends EntityMeta<Expense> {
  entity = Expense

  fields = {
    id: { type: String, backendKey: 'date' },
    date: { type: String },
    totalAmount: { type: Number, backendKey: 'total_amount' }
  }
}

export class TransactionAnalyticsByCategory extends Entity {
  expenses: Expense[] = []
  categorySetting: CategorySetting = new CategorySetting()
}

export class TransactionAnalyticsByCategoryMeta extends EntityMeta<TransactionAnalyticsByCategory> {
  entity = TransactionAnalyticsByCategory
  endpoint = '/transaction/analytics/by_category/'

  fields = {
    expenses: { type: ExpenseMeta, many: true },
    categorySetting: { type: CategorySettingMeta, backendKey: 'category_setting' }
  }
}

type ColumnsDiagramData = {
  xAxisLabels: string[],
  yAxisLabels: Array<{ label: string, value: number }>,
  values: number[],
  legendItems: Array<{
    title: string,
    color: string
  }>
}

export class HistogramDay {
  date: string = ''
  amount: number = 0
  background: string = ''
  category: string = ''
}

export class HistogramYAxisLabel {
  label: string = ''
  value: number = 0
}

export class TransactionAnalyticsByExpensesName extends Entity {
  expenses: Expense[] = []
  expensesName: ExpensesName = new ExpensesName()
}

export class TransactionAnalyticsByExpensesNameMeta extends EntityMeta<TransactionAnalyticsByExpensesName> {
  entity = TransactionAnalyticsByExpensesName
  endpoint = '/transaction/analytics/by_expenses_name/'

  fields = {
    expenses: { type: ExpenseMeta, many: true },
    expensesName: { type: ExpensesNameMeta, backendKey: 'expenses_name' }
  }
}

export class TransactionAnalyticsByExpensesType extends Entity {
  expenses: Expense[] = []
  expensesType: ExpensesType = new ExpensesType()
}

export class TransactionAnalyticsByExpensesTypeMeta extends EntityMeta<TransactionAnalyticsByExpensesType> {
  entity = TransactionAnalyticsByExpensesType
  endpoint = '/transaction/analytics/by_expenses_type/'

  fields = {
    expenses: { type: ExpenseMeta, many: true },
    expensesType: { type: ExpensesTypeMeta, backendKey: 'expenses_type' }
  }
}

export { GraphicDiagramData, IGraphicDiagram, StyleLine, ColumnsDiagramData }