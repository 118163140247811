import { defineComponent, inject, Ref, ref } from 'vue'
import { TSidebarState } from '../types'
import Account from './accounts'
import Category from './category'

const AccountsIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.99987L11.3224 1.19799C12.1279 0.622596 13.2599 1.03939 13.5 1.99975L9 4.99987H6ZM18.3139 2.94143L19 4.99987H10.5L15.335 1.89152C16.4327 1.18582 17.9012 1.70341 18.3139 2.94143ZM5.75 6.24968C3.67893 6.24968 2 7.92861 2 9.99968V17.9997C2 20.0707 3.67893 21.7497 5.75 21.7497H17.75C19.8211 21.7497 21.5 20.0707 21.5 17.9997V17.7497H21.5001V16.4997C22.6046 16.4996 23.5 15.6042 23.5 14.4997C23.5 13.3951 22.6046 12.4997 21.5001 12.4997V11.2497H21.5V9.99968C21.5 7.92861 19.8211 6.24968 17.75 6.24968H5.75ZM21.5 11.2497H20.0483H18.5001C16.7051 11.2497 15.2501 12.7048 15.2501 14.4997C15.2501 16.2946 16.7051 17.7497 18.5001 17.7497H21.5V16.692V16.4997H18.5C17.3954 16.4997 16.5 15.6042 16.5 14.4997C16.5 13.3951 17.3954 12.4997 18.5 12.4997H21.5V11.3074V11.2497Z" fill={'#fff'}/>
</svg>

const CategoriesIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02727 3C4.39575 3 3 4.36126 3 6V8.01923C3 9.77003 4.41685 10.9887 5.96397 11.0978C5.98504 11.0993 6.00615 11.1 6.02727 11.1H8.07273C9.70425 11.1 11.1 9.73874 11.1 8.1V6C11.1 4.40745 9.83161 3 8.15455 3H6.02727ZM15.9273 3C14.2957 3 12.9 4.36126 12.9 6V8.1C12.9 9.73874 14.2957 11.1 15.9273 11.1H17.9727C19.6043 11.1 21 9.73874 21 8.1V6C21 4.40745 19.7316 3 18.0545 3H15.9273ZM6.02727 12.9C4.39575 12.9 3 14.2613 3 15.9V18C3 19.6907 4.43976 20.8903 5.96397 20.9978C5.98504 20.9993 6.00615 21 6.02727 21H8.07273C9.70425 21 11.1 19.6387 11.1 18V15.9C11.1 14.3074 9.83161 12.9 8.15455 12.9H6.02727ZM15.9273 12.9C14.2957 12.9 12.9 14.2613 12.9 15.9V18C12.9 19.6387 14.2957 21 15.9273 21H17.9727C19.6043 21 21 19.6387 21 18V15.9C21 14.3074 19.7316 12.9 18.0545 12.9H15.9273Z" fill={'#fff'}/>
</svg>

export default defineComponent({
  setup () {
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('settings-accounts-list'))
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const isMobile: Ref<boolean> = inject('isMobile', ref(false))

    return () => <div class='sidebar-settings sidebar-wrapper'>
      { sidebarState.value.includes('accounts')
        ? <Account/>
        : <Category/>
      }
      {(!isMobile.value || sidebarState.value === 'settings') && <div class='sidebar-border-left'>
        <div class='sidebar-cell shadow head'>
          <div class='flex-sb'>
            <h4>Настройки</h4>
            <button onClick={() => { onChangeState('default') }} >
              <img src={require('@/assets/img/close.svg')} alt='' />
            </button>
          </div>
        </div>
        <div class='sidebar-cell'>
          <div class='sidebar-settings-tabs'>
            <div
              aria-label='button'
              class={{ 'sidebar-settings-tab mb-2 flex-sb': true, active: sidebarState.value.includes('accounts') }}
              onClick={() => { onChangeState('settings-accounts-list') }}
            >
              <span>Счета</span>
              <div class='sidebar-settings-tab-image flex-center'>
                { AccountsIcon() }
              </div>
            </div>
            <div
              aria-label='button'
              class={{ 'sidebar-settings-tab mb-2 flex-sb': true, active: sidebarState.value.includes('category') }}
              onClick={() => { onChangeState('settings-category-list') }}
            >
              <span>Категории</span>
              <div class='sidebar-settings-tab-image flex-center'>
                { CategoriesIcon() }
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  }
})
