import './custom-input-button.sass'
import { defineComponent, PropType, Ref, ref } from 'vue'

export const CustomInputButton = defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    onChange: {
      type: Function as PropType<() => void>,
      required: true
    },
    onCreateNewExpensesName: {
      type: Function as PropType<(name: string) => void>,
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    icon: {
      type: Function as PropType<() => JSX.Element>,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    expenseName: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const search: Ref<string> = ref('')
    const handleInput = () => {
      if (search.value.length > 1) {
        return props.onCreateNewExpensesName?.(search.value)
      }
    }
    return () => <div> {props.expenseName ? (
      <div
        class={{ 'custom-input-button': true, active: props.active }}
        aria-label='button'
      >
        <div class='custom-input-button-left'>
          <p class='custom-input-button-placeholder text secondary-color '>{ props.placeholder }</p>
          <input class='custom-input-button-selcted' type="text" value={props.modelValue} onInput={(e: Event) => { search.value = (e.target as HTMLInputElement).value }} onBlur={handleInput} />
        </div>
        <div class='custom-input-button-right flex-center' onClick={props.onChange}>{ props.icon() }</div>
      </div>
    ) : (
      <div
        class={{ 'custom-input-button': true, active: props.active }}
        aria-label='button'
        onClick={props.onChange}
      >
        <div class='custom-input-button-left'>
          <p class='custom-input-button-placeholder text secondary-color '>{ props.placeholder }</p>
          <p class='custom-input-button-selcted'>{ props.modelValue }</p>
        </div>
        <div class='custom-input-button-right flex-center'>{ props.icon() }</div>
      </div>
    )}
    { props.errors.map(error => <div class="error-text text mt-1">{error}</div>) }
    </div>
  }
})
