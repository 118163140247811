import './histogram.sass'
import { defineComponent, ref, Ref, PropType, onBeforeMount, watch } from 'vue'
import HistogramGenerate from './graphic-histogram-generate-categories'
import SegmentDiagramGenerate from '../segment-diagram/segment-diagram-generate-categories'
import { TransactionAnalyticsByCategory, TransactionAnalyticsByCategoryMeta, Expense } from '../types'
import { useStore } from 'vuex'
import { axios } from '@/axios'

export default defineComponent({
  props: {
    defaultFilter: {
      type: Object as PropType<{[key: string]: string }>,
      required: true
    },
    isYear: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const transactionAnalyticsByCategory: Ref<TransactionAnalyticsByCategory[]> = ref([])

    onBeforeMount(() => {
      load()
    })

    watch(() => props.defaultFilter, () => {
      load()
    })

    const load = async () => {
      await getTransactionAnalytics()
    }

    const getTransactionAnalytics = async () => {
      const params = { ...props.defaultFilter }
      if (params.date) delete params.date

      const result = await axios.get(new TransactionAnalyticsByCategoryMeta().endpoint, { params })
      transactionAnalyticsByCategory.value = result.data.map((i: any) => new TransactionAnalyticsByCategoryMeta().load(i))
      setExpenses(transactionAnalyticsByCategory.value)
    }

    const store = useStore()
    const setExpenses = (data: TransactionAnalyticsByCategory[]) => {
      const expenses: Expense[] = []
      data.forEach(i => expenses.push(...i.expenses))
      store.commit('setExpenses', expenses)
    }

    return () => transactionAnalyticsByCategory.value.length
      ? <div class="histogram">
        <div class="main-grid">
          <div class="box-white">
            <HistogramGenerate items={transactionAnalyticsByCategory.value} isYear={props.isYear} />
          </div>
          <div class="box-white">
            <SegmentDiagramGenerate items={transactionAnalyticsByCategory.value} />
          </div>
        </div>
      </div>
      : <p>Нет расходов за данный период</p>
  }
})
