import './custom-relation-select.sass'
import { LineLoader, useClickOutside, usePaginatedApi, useScrollPagination } from 'apptimizm-ui'
import IAxiosInterface from 'apptimizm-ui/src/IAxiosInterface'
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'
import { useToggle } from '@/composables/use-toggle'

export function defineCustomRelationSelect<T extends { id: string, title: string }> () {
  return defineComponent({
    props: {
      axios: {
        type: Object as PropType<IAxiosInterface>,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      endpoint: {
        type: String,
        required: true
      },
      errors: {
        type: Array as PropType<string[]>,
        default: () => []
      },
      itemConverter: {
        type: Function as PropType<(item: any) => T>,
        required: true
      },
      modelValue: {
        type: Object as PropType<T|undefined>,
        default: undefined
      },
      onValueChange: {
        type: Function as PropType<(v: T) => void>,
        default: () => () => {}
      },
      paginationType: {
        type: String,
        default: 'page'
      },
      params: {
        type: Object as PropType<{ [code: string] : string|string[] }>,
        default: () => ({})
      },
      perPage: {
        type: Number,
        default: 10
      },
      placeholder: {
        type: String,
        default: ''
      },
      preselected: {
        type: Boolean,
        default: false
      },
      requestPageKey: {
        type: String,
        default: 'page'
      },
      requestPerPageKey: {
        type: String,
        default: 'per_page'
      },
      responseItemsKey: {
        type: String,
        default: 'results'
      },
      responseTotalKey: {
        type: String,
        default: 'count'
      },
      searchKey: {
        type: String,
        default: 'search'
      },
      icon: {
        type: Function as PropType<() => JSX.Element>,
        required: true
      }
    },

    setup (props) {
      const isOpened = ref(false)
      const trigger = ref(null) as unknown as Ref<HTMLElement>
      const root = ref(null) as unknown as Ref<HTMLElement>
      const select = ref(null) as unknown as Ref<HTMLElement>
      const search = ref('')
      const queryParams = computed(() => {
        const params = { ...props.params }
        if (search.value !== '') params[props.searchKey] = search.value
        return params
      })

      const {
        page,
        pages,
        count,
        isLoading,
        items,
        load,
        reload,
        loadPrev,
        loadNext,
        loadPage
      } = usePaginatedApi(
        props.endpoint,
        props.axios,
        queryParams,
        ref(props.perPage),
        props.paginationType,
        props.itemConverter,
        true,
        props.responseItemsKey,
        props.responseTotalKey,
        'ordering',
        props.requestPageKey,
        props.requestPerPageKey
      )

      if (props.preselected) {
        const preselectedCallback = () => {
          if (items.value.length > 0) {
            props.onValueChange(items.value[0])
            page.value++
          }
        }

        onMounted(() => {
          load(preselectedCallback)
        })
      }

      useScrollPagination(() => pages.value === 0 ? load() : loadNext(), trigger, root)

      watch(() => props.params, () => {
        // мгновенная перезагрузка происходит только в том случае, если селект раскрыт, иначе она будет выполена отложенно при раскрытии
        if (isOpened.value) {
          reload()
        } else {
          items.value = []
          pages.value = 0
          page.value = 1
        }
      })

      useClickOutside(select, () => { isOpened.value = false })

      const setValue = (item: T) => {
        props.onValueChange(item)
        isOpened.value = false
      }

      const value = computed(() => props.modelValue as T)

      return () => {
        return (
          <div>
            <div
              class={`custom-relation-select ${isOpened.value ? 'opened' : ''} ${props.disabled ? 'disabled' : ''}`}
              ref={select}
            >
              <div class="custom-relation-select-header" onClick={() => useToggle(isOpened)}>
                <div>
                  { isOpened.value ? (
                    <input
                      type='text'
                      placeholder={props.placeholder}
                      value={search.value}
                      onInput={(v: Event) => { search.value = (v.target as HTMLFormElement).value; reload() }}
                    />
                  ) : <div class="custom-relation-select-placeholder">{props.placeholder}</div> }
                  {value.value.id && <div class='custom-relation-select-selected'>{ value.value.title }</div>}
                </div>
                <div class='custom-relation-select-icon flex-center' onClick={() => useToggle(isOpened)}>{ props.icon() }</div>
              </div>
              <div class="custom-relation-select-dropdown scroll-bar" style={isOpened.value ? 'display: block;' : 'display: none;'}>
                { isLoading.value && <LineLoader/> }
                <div class="custom-relation-select-items-list" ref={root}>
                  { items.value.map((item: any) => (
                    <div
                      class={`custom-relation-select-item ${item.id === value.value?.id ? 'is-selected' : ''}`}
                      onClick={() => setValue(item)}
                    >
                      <span>{ item.title }</span>
                    </div>
                  )) }
                  <div ref={trigger}/>
                </div>
              </div>
            </div>
            { props.errors.map(error => <div class="error-text text mt-1">{error}</div>) }
          </div>
        )
      }
    }
  })
}
