import './histogram.sass'
import { defineComponent, ref, Ref, PropType, onBeforeMount, watch } from 'vue'
import HistogramGenerate from './graphic-histogram-generate-expenses-type'
import SegmentDiagramGenerate from '../segment-diagram/segment-diagram-generate-expenses-type'
import { TransactionAnalyticsByExpensesType, TransactionAnalyticsByExpensesTypeMeta, Expense } from '../types'
import { useStore } from 'vuex'
import { axios } from '@/axios'
import { errorPush } from '@/core/ErrorPopup/func'

export default defineComponent({
  props: {
    defaultFilter: {
      type: Object as PropType<{[key: string]: string }>,
      required: true
    },
    isYear: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const transactionAnalyticsByExpensesType: Ref<TransactionAnalyticsByExpensesType[]> = ref([])

    onBeforeMount(() => {
      load()
    })

    watch(() => props.defaultFilter, () => {
      load()
    })

    const load = async () => {
      await getTransactionAnalytics()
    }

    const getTransactionAnalytics = async () => {
      const params = { ...props.defaultFilter }
      if (params.date) delete params.date

      const result = await axios.get(new TransactionAnalyticsByExpensesTypeMeta().endpoint, { params })
      transactionAnalyticsByExpensesType.value = result.data.map((i: any) => new TransactionAnalyticsByExpensesTypeMeta().load(i))
      setExpenses(transactionAnalyticsByExpensesType.value)
    }

    const store = useStore()
    const setExpenses = (data: TransactionAnalyticsByExpensesType[]) => {
      const expenses: Expense[] = []
      data.forEach(i => expenses.push(...i.expenses))
      store.commit('setExpenses', expenses)
    }

    return () => transactionAnalyticsByExpensesType.value.length
      ? <div class='histogram'>
        <div class="box-white mb-2">
          <HistogramGenerate items={transactionAnalyticsByExpensesType.value} isYear={props.isYear}/>
        </div>
        <div class="box-white segment-diagram-big mb-2">
          <SegmentDiagramGenerate items={transactionAnalyticsByExpensesType.value} />
        </div>
      </div>
      : <p>Нет расходов за данный период</p>
  }
})
