import { axios } from '@/axios'
import Accordion from '@/components/accordion/accordion'
import { useColorFilter } from '@/composables/use-color-filter'
import { ExpensesType, ExpensesTypeMeta, Icon } from '@/core/types'
import { CustomInput } from '@/UI/input/custom-input'
import { FormErrors } from 'apptimizm-ui'
import { computed, defineComponent, inject, onBeforeMount, PropType, ref, Ref } from 'vue'
import { TSidebarState } from '../../types'

export default defineComponent({
  props: {
    onCreatingExpensesType: {
      type: Function as PropType<() => void>,
      required: true
    },
    categoryType: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const item: Ref<ExpensesType> = ref(new ExpensesType())
    const errors: Ref<FormErrors> = ref(new FormErrors({}))
    const colors: Ref<Array<string>> = inject('colors', ref([]))
    const icons: Ref<Array<Icon>> = ref([])

    onBeforeMount(() => {
      axios.get('/file/icon/', { params: { type: 'expenses_type' } })
        .then(({ data }) => {
          icons.value = data.results
          item.value.icon = icons.value[0]
          item.value.backgroundColor = colors.value[0]
        })
    })

    const submit = () => {
      axios.post(new ExpensesTypeMeta().endpoint, { title: item.value.title, icon: item.value.icon.id, category: props.categoryType, icon_background_color: item.value.backgroundColor })
        .then(({ data }) => {
          if (data.errors) errors.value = new FormErrors(data.errors)
          else props.onCreatingExpensesType()
        })
    }

    const disabled = computed(() => {
      if (!item.value.title) return true
      return false
    })

    const ExpensesTypeIcon = {
      head: (): JSX.Element => (
        <div class='flex' style={{ alignItems: 'center' }}>
          <div class='sidebar-category-icon-selected mr-2'>
            <img src={item.value.icon.file} alt='' style={useColorFilter(item.value.backgroundColor || '#000')}/>
          </div>
          <p>Иконка категории</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='sidebar-category-icons-list'>
          { icons.value.map(icon => <div
            class={{ 'sidebar-category-icon flex-center': true, active: icon.id === item.value.icon.id }}
            onClick={() => { item.value.icon = icon }}
          >
            <img src={icon.file} alt='' />
          </div>)}
        </div>
      )
    }

    const ExpensesTypeColor = {
      head: (): JSX.Element => (
        <div class='flex'>
          <div class='sidebar-accounts-color mr-2' style={{ backgroundColor: item.value.backgroundColor }}/>
          <p>Цвет категории</p>
        </div>
      ),
      body: (): JSX.Element => (
        <div class='flex wrap'>
          { colors.value.map(color => <div
            class='sidebar-accounts-color mr-1 mb-1'
            style={{ backgroundColor: color, cursor: item.value.backgroundColor === color ? 'default' : 'pointer' }}
            onClick={() => { item.value.backgroundColor = color }}
          />)}
        </div>
      )
    }

    return () => <div class='sidebar-expenses-name sidebar-extension-block sidebar-border-left'>
      <div class='sidebar-cell head shadow'>
        <h4>Тип расхода в категории</h4>
      </div>
      <div class='sidebar-cell'>
        <CustomInput
          modelValue={item.value.title}
          onValueChange={(v: string) => { item.value.title = v }}
          onClearValue={() => { item.value.title = '' }}
          errors={errors.value.forField('title')}
        />
        <div class='cell-line'/>
      </div>
      <div class='sidebar-cell'>
        <Accordion slots={ExpensesTypeIcon}/>
        { errors.value.forField('icon') && <p>{errors.value.forField('icon')}</p> }
      </div>
      <div class='sidebar-cell'>
        <Accordion slots={ExpensesTypeColor}/>
        { errors.value.forField('icon_background_color') && <p>{errors.value.forField('icon_background_color')}</p> }
      </div>
      <div class='sidebar-cell'>
        <div class='primary-grid-2'>
          <button class='default-button full-width' onClick={props.onCreatingExpensesType}>Отменить</button>
          <button class='primary-button full-width' disabled={disabled.value} onClick={submit}>Сохранить</button>
        </div>
      </div>
    </div>
  }
})
