import './histogram.sass'
import { defineComponent, PropType, ref, onMounted } from 'vue'
import { HistogramDay, HistogramYAxisLabel } from '../types'
import { EnumType } from '@/core/types'
import { useStore } from 'vuex'
import cn from 'classnames'
import moment, { Moment } from 'moment'

export default defineComponent({
  props: {
    xAxisLabels: {
      type: Array as PropType<EnumType[]>,
      required: true
    },
    yAxisLabels: {
      type: Array as PropType<HistogramYAxisLabel[]>,
      required: true
    },
    days: {
      type: Array as PropType<HistogramDay[][]>,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    isSort: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const dayActive = ref('')

    const column = (day: HistogramDay[], idx: number) => {
      const date = props.xAxisLabels[idx].name
      if (props.isSort) day.sort((a, b) => Number(b.amount) - Number(a.amount))
      return (
        <div class="column" key={`${date}-${idx}`} onClick={() => setDate(day[0].date)}>
          {day.map(i => {
            const height = i.amount / props.max * 100
            return (
              <div
                class="column-val"
                style={{
                  height: height + '%',
                  background: i.background
                }}
              ></div>
            )
          })}
          <div class={cn('date-val', { active: dayActive.value === day[0].date })}>{date}</div>
        </div>
      )
    }

    const store = useStore()
    const setDate = (date: string) => {
      store.commit('setDate', moment(date))
      dayActive.value = date
    }

    return () => <div class='graphic-histogram'>
      <div class="y-axis">
        {props.yAxisLabels.map(label => <div class="y-axis-label" style={`bottom: ${label.value}%`}>{label.label}</div>)}
      </div>
      <div class="graphic-body scroll-bar">
        <div class="column-wrap">
          {props.days.map((day, idx) => column(day, idx))}
          {props.yAxisLabels.map(label =>
            <div
              key={label.value}
              class="y-axis-line"
              style={{
                bottom: label.value + '%',
                width: 36 * props.days.length + 'px'
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  }
})
