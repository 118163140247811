import './sidebar.sass'
import { defineComponent, inject, ref, Ref, TransitionGroup, watch } from 'vue'
import { TSidebarState } from './types'
import SidebarDefault from './default'
import SidebarSettings from './settings'
import Transaction from './transaction/transaction'

export default defineComponent({
  setup (_, { slots }) {
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('default'))
    const selectedTransactionId: Ref<string|null> = ref(null)
    watch(sidebarState, (to) => {
      if (!to.includes('new-transaction')) selectedTransactionId.value = null
    })
    return () => <aside
      class={{
        sidebar: true,
        extension: sidebarState.value.includes('settings') || sidebarState.value.includes('extension')
      }}>
      <TransitionGroup
        appear
        enterToClass='sidebar-enter'
        enterActiveClass='sidebar-enter'
        leaveToClass='sidebar-out'
        leaveActiveClass='sidebar-out'
      >
        { sidebarState.value.includes('settings') && <SidebarSettings key='settings'/> }
        { sidebarState.value === 'default' && <SidebarDefault
          key='default'
          onSetTransactionId={(id: string) => { selectedTransactionId.value = id }}
        >
          { slots.default && slots.default() }
        </SidebarDefault> }
        { sidebarState.value.includes('new-transaction') && <Transaction
          key='transaction'
          selectedTransactionId={selectedTransactionId.value}
        /> }
      </TransitionGroup>
    </aside>
  }
})
