
import { Vue, prop, Options } from 'vue-class-component'
import GraphicDiagram from './graphic-diagram.vue'
import { TransactionAnalytics } from '../types'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

class Props {
  items = prop<TransactionAnalytics[]>({ required: true })
  labelFormat = prop<string>({ default: () => 'hours' }) // days, months
}

@Options({
  watch: {
    items () { this.generateNetworkData() }
  }
})

export default class GraphicGenerate extends Vue.with(Props) {
  itemsRead: any[] = []
  networkData = {
    xAxisLabels: [''],
    yAxisLabels: [
      { label: '', value: 0 },
      { label: '', value: 16.6 },
      { label: '', value: 33.2 },
      { label: '', value: 49.8 },
      { label: '', value: 66.4 },
      { label: '', value: 83 },
      { label: '', value: 100 }
    ],
    lines: [
      {
        type: 'line',
        name: 'Проектные точки',
        color: '#9747FF',
        points: [0]
      }
    ]
  }

  styleLine = [
    {
      strokeDasharray: '',
      strokeWidth: 3,
      gradient: 'purple-blue',
      legendType: true
    }
  ]

  minVal: number = 0
  maxVal: number = 100

  isMounted: boolean = false

  async mounted () {
    await this.generateNetworkData()
    this.isMounted = true
  }

  generateNetworkData () {
    // this.items.sort(this.compareDate)
    this.networkData.xAxisLabels = this.items.map(v => {
      let str = String(v.date)
      if (this.labelFormat === 'hour') str = str + 'ч'
      if (this.labelFormat === 'months') str = moment(v.id, 'YYYY-MM-DD').format('MMM')
      return str
    })
    this.generateYAxisLabels()
    this.generateItemsRead()
  }

  compareDate (a: any, b: any) : any {
    if (Number(b.date) > Number(a.date)) return -1
    if (Number(b.date) < Number(a.date)) return 1
  }

  generateYAxisLabels () {
    let valuesSum = [...this.items.map(v => (v.sum))]
    valuesSum = valuesSum.sort((a, b) => a - b)

    this.maxVal = Math.ceil((valuesSum[valuesSum.length - 1] - (valuesSum[0] - valuesSum[valuesSum.length - 1]) / 100 * 5) / 10) * 10

    const yAxisLabels = this.networkData.yAxisLabels.map(v => {
      const val = Math.floor((this.minVal + ((this.maxVal - this.minVal) / 100 * v.value)) / 10) * 10

      return {
        label: val > 1000 ? (val / 1000).toFixed(1) + ' т' : val.toFixed(0),
        value: v.value
      }
    })

    this.networkData.yAxisLabels = yAxisLabels

    this.generatePoints(0, 'sum')
  }

  generatePoints (idx: number, key: string) {
    const val = this.items.map((v: any) => Number(((v[key] - this.minVal) / ((this.maxVal - this.minVal) / 100)).toFixed(1)))
    this.networkData.lines[idx].points = val
  }

  generateItemsRead () {
    this.itemsRead = [this.items.map((v: any) => v)]
  }

  render () {
    return (
      <div class="box">
        {this.isMounted && <GraphicDiagram
          data={this.networkData}
          showLabelY={true}
          styleLine={this.styleLine}
          minYValue={this.minVal}
          maxYValue={this.maxVal}
          itemsRead={this.itemsRead}
        />}
      </div>
    )
  }
}
