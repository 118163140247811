import { useToggle } from '@/composables/use-toggle'
import { Accounts, BaseAccount } from '@/core/types'
import { useScrollPagination } from 'apptimizm-ui'
import { defineComponent, inject, onMounted, PropType, ref, Ref } from 'vue'
import { TSidebarState } from '../../types'
import AccountCard from './account-card'

export default defineComponent({
  props: {
    onChangeState: {
      type: Function as PropType<(state: TSidebarState, id?: string) => void>,
      required: true
    }
  },
  setup (props) {
    const isEdited: Ref<boolean> = ref(false)
    const items: Ref<Array<Accounts>> = inject('accounts', ref([]))
    const selectedAccount: Ref<Accounts> = inject('selectedAccount', ref(new Accounts()))
    const isMobile: Ref<boolean> = inject('isMobile', ref(false))
    const loadNextAccount = inject('loadNextAccount', () => {})
    const target = ref(null) as unknown as Ref<HTMLElement>

    useScrollPagination(loadNextAccount, target)

    return () => <div class={{ 'sidebar-accounts-list sidebar-extension-block sidebar-border-left': true, edtited: isEdited.value }}>
      {!isEdited.value && <>
        <div class='sidebar-cell shadow head'>
          <div class='flex-sb'>
            <div class='flex'>
              { !isMobile.value && <button onClick={() => props.onChangeState('settings')}><img src={require('@/assets/img/double-arrow.svg')} alt='' /></button> }
              <h4 class='ml-1'>Счета</h4>
            </div>
            <button onClick={() => useToggle(isEdited)}>
              <img src={require('@/assets/img/edit.svg')} alt='' />
            </button>
          </div>
        </div>
        <div class='sidebar-cell'>
          <div class='flex-sb link m-cell' onClick={() => props.onChangeState('settings-accounts-new')}>
            <p class='mr-1'>Добавить новый счет</p>
            <img src={require('@/assets/img/plus-primary.svg')} alt='' />
          </div>
          <div class='cell-line'/>
        </div>
      </>}

      {isEdited.value && <div class='sidebar-cell shadow head'>
        <h4>Редактирование счета</h4>
      </div>}

      <div class='sidebar-account-card-wrapper sidebar-scroll-container scroll-bar'>
        { !isEdited.value && <AccountCard
          onChangeState={props.onChangeState}
          isEdited={isEdited.value} item={BaseAccount}
          slectedAccountId={selectedAccount.value.id}
        /> }
        { items.value.map(item => <AccountCard
          onChangeState={props.onChangeState}
          isEdited={isEdited.value} item={item}
          slectedAccountId={selectedAccount.value.id}
        />) }
        <div ref={target}/>
      </div>

      {isEdited.value && <div class='sidebar-cell'>
        <div class='primary-grid-2'>
          <button class='default-button full-width' onClick={() => useToggle(isEdited)}>Отменить</button>
          <button class='primary-button full-width'>Сохранить</button>
        </div>
      </div>}
    </div>
  }
})
