import './segment-diagram.sass'
import { defineComponent, ref, Ref, PropType, watch, onMounted } from 'vue'
import { TransactionAnalyticsByExpensesType, Expense, HistogramDay } from '../types'
import { Segment, Info } from './types'
import { ExpensesType } from '@/core/types'
import SegmentDiagram from './segment-diagram'
import cn from 'classnames'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TransactionAnalyticsByExpensesType[]>,
      required: true
    }
  },
  setup (props) {
    const colors: Ref<string[]> = ref([])
    const info: Ref<Info> = ref(new Info())
    const categories: Ref<ExpensesType[]> = ref([])
    const data: Ref<Segment[]> = ref([])

    onMounted(() => {
      reload()
    })

    watch(() => props.items, () => {
      reload()
    })

    const reload = async () => {
      await generateDays()
    }

    const generateDays = () => {
      categories.value = []
      const daysTemplate: HistogramDay[] = []
      props.items.forEach((item: TransactionAnalyticsByExpensesType) => {
        item.expenses.forEach((expense: Expense) => {
          const val: HistogramDay = new HistogramDay()
          val.date = expense.date
          val.amount = expense.totalAmount
          val.background = item.expensesType.backgroundColor
          val.category = item.expensesType.title
          daysTemplate.push(val)
        })
        categories.value.push(item.expensesType)
      })

      generateData(daysTemplate)
    }

    const generateData = (items: HistogramDay[]) => {
      colors.value = []
      data.value = []
      const values: Segment[] = []
      categories.value.forEach(category => {
        let sum = 0
        const arr = items.filter(i => i.category === category.title)
        arr.forEach(i => { sum += i.amount })
        values.push({ id: category.id, name: category.title, value: sum })
        colors.value.push(category.backgroundColor)
      })

      let total = 0
      values.forEach(i => { total += i.value })
      data.value = values.map(i => {
        return {
          id: i.id,
          name: i.name,
          value: i.value / total * 100,
          sum: i.value
        }
      })
      info.value.total = 100
    }

    return () => <div class="segment-diagram-generate">
      <SegmentDiagram
        indicatorInfo={info.value}
        indicatorData={data.value}
        size={216}
        strokeWidth={22}
        colors={colors.value}
      />
      <div>
        <div class={cn('legend', { unlimited: categories.value.length > 8 })}>
          {categories.value.map((item, idx) => {
            return (
              <div key={item.id} class="box-item">
                <div class="legend-item">
                  <div class="flex">
                    <div
                      class="legend-item-circle"
                      style={{
                        background: colors.value[idx]
                      }}
                    ></div>
                    <div class="legend-item-title">{item.title}</div>
                  </div>
                  <div
                    class="legend-item-title"
                    style={{
                      color: item.backgroundColor
                    }}
                  >{Math.round(data.value.find(i => i.name === item.title)?.value || 0)}%</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  }
})
