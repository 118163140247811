import { axios } from '@/axios'
import Accordion, { AccordionExposed } from '@/components/accordion/accordion'
import { useColorFilter } from '@/composables/use-color-filter'
import { CategorySetting, ExpensesType, ExpensesTypeMeta } from '@/core/types'
import { usePaginatedApi } from 'apptimizm-ui'
import { defineComponent, inject, onBeforeMount, PropType, Ref, ref, watch } from 'vue'
import { debounce } from 'lodash'
import { CustomInput } from '@/UI/input/custom-input'
import { TSidebarState } from '../types'
import { useObserver } from '@/composables/use-observer'

export default defineComponent({
  props: {
    onChangeState: {
      type: Function as PropType<(state: TSidebarState) => void>,
      required: true
    },
    onChangeItem: {
      type: Function as PropType<(item: ExpensesType) => void>,
      required: true
    }
  },
  setup (props) {
    const meta = new ExpensesTypeMeta()
    const fixedTarget = ref(null) as unknown as Ref<HTMLElement>
    const dynamicTarget = ref(null) as unknown as Ref<HTMLElement>
    const category: Ref<Array<CategorySetting>> = inject('categorySettings', ref([]))
    const lastUsedExpenses: Ref<ExpensesType[]> = ref([])
    const search: Ref<string> = ref('')
    const paramsFixed: { [key: string]: string } = { category: 'fixed' }
    const paramsDynamic: { [key: string]: string } = { category: 'dynamic' }
    const lastAccordion = ref(null) as unknown as Ref<AccordionExposed>
    const {
      items: fixedItems,
      reload: reloadFixed,
      loadNext: loadNextFixed
    } = usePaginatedApi(
      meta.endpoint,
      axios,
      ref(paramsFixed),
      ref(10),
      'page',
      (x) => meta.load(x),
      true,
      'results',
      'count'
    )

    const {
      items: dynamicItems,
      reload: reloadDynamic,
      loadNext: loadNextDynamic
    } = usePaginatedApi(
      meta.endpoint,
      axios,
      ref(paramsDynamic),
      ref(10),
      'page',
      (x) => meta.load(x),
      true,
      'results',
      'count'
    )

    const loadLastUsedExpenses = () => {
      axios.get(meta.endpoint + 'last_used/')
        .then(({ data }) => { lastUsedExpenses.value = data.map((x: any) => meta.load(x)) })
    }

    const deleteLastUsedExpenses = () => {
      axios.delete(meta.endpoint + 'last_used/clear/')
        .then(loadLastUsedExpenses)
    }

    const calback = debounce(() => { reloadDynamic(); reloadFixed() }, 500)

    onBeforeMount(() => {
      loadLastUsedExpenses()
      reloadDynamic()
      reloadFixed()
    })

    useObserver(loadNextDynamic, dynamicTarget)
    useObserver(loadNextFixed, fixedTarget)

    watch(search, to => {
      if (to.trim().length) {
        paramsFixed.search = to
        paramsDynamic.search = to
      } else {
        delete paramsFixed.search
        delete paramsDynamic.search
      }

      calback()
    })

    watch(() => lastAccordion.value?.isOpen, to => {
      if (to) {
        setTimeout(() => {
          const wrap = (document.querySelector('.sidebar-transaction-type .sidebar-scroll-container'))
          wrap && wrap.scrollTo({ top: 1000, behavior: 'smooth' })
        }, 0)
      }
    })

    const renderTypes = (items: ExpensesType[]): JSX.Element => <div>
      { items.map(item => <div
        class='flex pt-1 pb-1 pl-4'
        style={{ cursor: 'pointer' }}
        onClick={() => { props.onChangeItem(item); props.onChangeState('new-transaction') }}
      >
        <div class='expenses-types-item-icon mr-1 flex-center' style={{ backgroundColor: item.backgroundColor }}>
          <img src={item.icon.file} alt='' style={useColorFilter('#ffffff')}/>
        </div>
        <p>{ item.title }</p>
      </div>)}
    </div>

    return () => <div class='sidebar-transaction-type sidebar-extension-block sidebar-border-left'>
      <div class='sidebar-transaction-type-wrapper'>
        <div class='sidebar-cell head shadow'>
          <div class='flex-sb'>
            <div class='flex'>
              <button class='mr-1' onClick={() => props.onChangeState('new-transaction')}>
                <img src={require('@/assets/img/double-arrow.svg')} alt='' />
              </button>
              <h4>Тип расхода</h4>
            </div>
            <button onClick={() => props.onChangeState('settings-category-list')}>
              <img src={require('@/assets/img/edit.svg')} alt='' />
            </button>
          </div>
        </div>
        <div class='sidebar-cell'>
          <CustomInput
            modelValue={search.value}
            onClearValue={() => { search.value = '' }}
            onValueChange={(v: string) => { search.value = v }}
            placeholder='Поиск'
            search={true}
          />
        </div>
        <div class='sidebar-scroll-container scroll-bar' style={{ maxHeight: 'calc(100% - 300px)' }}>
          { lastUsedExpenses.value.length > 0 && <div class='sidebar-cell'>
            <div class='flex-sb mb-2'>
              <p class='desc secondary-color'>Последние использованные</p>
              <button class='link' onClick={deleteLastUsedExpenses}>Очистить</button>
            </div>
            <div>{ renderTypes(lastUsedExpenses.value) }</div>
          </div> }
          <div class='sidebar-cell sidebar-category-list'>
            <p class='desc secondary-color'>Все типы расходов</p>
            { category.value.map((item, idx) =>
              <div class='mt-2'>
                <Accordion ref={idx === 1 ? lastAccordion : ref()} slots={{
                  head: () => <div class='flex'>
                    <div class='sidebar-category-icon-wrapper flex-center'>
                      {item.icon.file ? <img src={item.icon.file} alt='' style={useColorFilter(item.backgroundColor)} /> : <img src={require(`@/assets/img/default-${item.category.value}-icon.svg`)} alt='' />}
                    </div>
                    <p class='mt-1 ml-2'>{ item.category.name }</p>
                  </div>,
                  body: () => <div class='sidebar-scroll-container scroll-bar'>
                    { item.category.value === 'dynamic' ? renderTypes(dynamicItems.value) : renderTypes(fixedItems.value) }
                    <div ref={ item.category.value === 'dynamic' ? dynamicTarget : fixedTarget }/>
                  </div>
                }}/>
              </div>
            )}
          </div>
        </div>
        <div class='sidebar-cell mr-2 ml-2 mt-3 sidebar-inner-container sidebar-transaction-type-bottom' style={{ backgroundColor: 'rgba(101, 83, 245, .1)', textAlign: 'center', borderRadius: '12px' }}>
          <p class='desc secondary-color'>Не нашли нужный тип расхода? Создайте необходимый вам</p>
          <button class='link flex-center' onClick={() => props.onChangeState('settings-category-list')} style={{ margin: '16px auto 0' }}>
            <img class='mr-1' src={require('@/assets/img/plus-primary.svg')} alt='' />
            <span>Создать тип расхода</span>
          </button>
        </div>
      </div>
    </div>
  }
})
