import './tabs.sass'
import { ListItem } from '@/core/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Array<ListItem>>,
      required: true
    },
    onChangeTab: {
      type: Function as PropType<(v: ListItem) => void>,
      required: true
    },
    selectedTab: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    return () => <div class={`default-tabs ${props.isMobile ? 'mobile' : null}`} style={{ gridTemplateColumns: `repeat(${props.tabs.length}, auto)` }}>
      { props.tabs.map(tab =>
        <button
          class={{ 'default-tab': true, active: props.selectedTab === tab.value }}
          onClick={() => { props.onChangeTab(tab) }}
        >
          {tab.name}
        </button>)
      }
    </div>
  }
})
