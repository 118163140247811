import './date-picker.sass'
import 'apptimizm-ui/src/ui/calendars/calendar.sass'
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue'
import { Calendar, useClickOutside } from 'apptimizm-ui'
import { useToggle } from '@/composables/use-toggle'
import moment, { Moment } from 'moment'

moment.locale('ru')

export const DatePicker = defineComponent({
  props: {
    placeholder: {
      type: String,
      default: 'Когда потратили'
    },
    modelValue: {
      type: String,
      default: ''
    },
    onValueChange: {
      type: Function as PropType<(v: string) => void>,
      required: true
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup (props) {
    const isOpen: Ref<boolean> = ref(false)
    const element = ref(null) as unknown as Ref<HTMLElement>
    useClickOutside(element, () => { isOpen.value = false })
    const selectedDay: Ref<Moment|null> = ref(props.modelValue ? moment(props.modelValue) : null)

    const onSetDay = (date: Moment) => {
      selectedDay.value = date
      props.onValueChange(date.format('YYYY-MM-DDThh:mm'))
      useToggle(isOpen)
    }

    const selectedValue = computed(() => {
      if (!selectedDay.value) return ''

      if (selectedDay.value.isSame(moment(), 'day')) return 'Сегодня'
      if (selectedDay.value.isSame(moment().add(-1, 'days'), 'day')) return 'Вчера'
      return selectedDay.value.format('DD MM YYYY')
    })

    return () => <div class='date-picker' ref={element}>
      <div onClick={() => useToggle(isOpen)}>
        <p class='date-picker-placeholder text'>{props.placeholder}</p>
        <p class='desc date-pocker-selected'>{ selectedValue.value }</p>
      </div>
      <div class='date-picker-icon flex-center' onClick={() => useToggle(isOpen)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 8.22388V10H2V8.22388C2 6.34328 3.5 4.77612 5.3 4.77612H6.1V6.55224C6.1 7.07463 6.5 7.38806 6.9 7.38806C7.3 7.38806 7.7 6.97015 7.7 6.55224V3.83582C7.8 3.41791 8.2 3 8.7 3C9.2 3 9.5 3.41791 9.5 3.83582V4.67164H13.7V6.44776C13.7 6.97015 14.1 7.28358 14.5 7.28358C14.9 7.28358 15.3 6.86567 15.3 6.44776V3.83582C15.3 3.31343 15.7 3 16.1 3C16.6 3 17 3.41791 17 3.83582V4.67164H18.7C20.5 4.77612 22 6.34328 22 8.22388Z" fill="#6553F5"/>
          <path d="M2 12H22V18.03C22 19.65 20.5 21 18.7 21H5.3C3.5 21 2 19.65 2 18.03V12Z" fill="#6553F5"/>
        </svg>
      </div>
      { isOpen.value && <div class='date-picker-dropdown'>
        <Calendar
          onSetDay={onSetDay}
          day={selectedDay.value}
        />
      </div> }
      { props.errors.map(error => <div class="error-text text mt-1">{error}</div>) }
    </div>
  }
})
