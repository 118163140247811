import { useColorFilter } from '@/composables/use-color-filter'
import { CategorySetting } from '@/core/types'
import { defineComponent, inject, PropType, Ref, ref } from 'vue'
import { TSidebarState } from '../../types'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<CategorySetting>>,
      required: true
    },
    onChangeSelectedCategory: {
      type: Function as PropType<(id: CategorySetting) => void>,
      required: true
    }
  },
  setup (props) {
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const isMobile: Ref<boolean> = inject('isMobile', ref(false))

    return () => <div class='sidebar-category-list sidebar-extension-block sidebar-border-left'>
      <div class='sidebar-cell shadow head flex-sb'>
        <h4>Редактирование</h4>
        {isMobile.value && <button onClick={() => { onChangeState('settings') }} >
          <img src={require('@/assets/img/close.svg')} alt='' />
        </button>}
      </div>
      <div class='sidebar-category-list-wrapper'>
        { props.items.map(item => <div
          class='sidebar-cell with-dashed-line flex-sb'
          style={{ cursor: 'pointer' }}
          onClick={() => { props.onChangeSelectedCategory(item) }}
        >
          <div class='flex'>
            <div class='sidebar-category-icon-wrapper flex-center'>
              { item.icon.file
                ? <img style={useColorFilter(item.backgroundColor)} src={item.icon.file} alt='' />
                : <img src={require(`@/assets/img/default-${item.category.value}-icon.svg`)} alt=''/>
              }
            </div>
            <p class='ml-2 mt-1'>{ item.category.name }</p>
          </div>
          <div>
            <div class='sidebar-accounts-card-arrow'>
              <img src={require('@/assets/img/arrow.svg')} alt='' />
            </div>
          </div>
        </div>) }
      </div>
    </div>
  }
})
