
import { Vue, prop } from 'vue-class-component'
import { GraphicDiagramData, StyleLine } from '../types'
import cn from 'classnames'
import { useStore } from 'vuex'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

class Props {
  itemsRead = prop<any[]>({ default: [] })
  data = prop<GraphicDiagramData>({ required: true })
  title = prop<string>({ default: '' })
  tooltipLabel = prop<string>({ default: '' })
  minYValue = prop<number>({ default: 0 })
  maxYValue = prop<number>({ default: 100 })
  smoothing = prop<number>({ default: 0 })
  showLabelY = prop<boolean>({ default: true })
  isShowNullValueTooltip = prop<boolean>({ default: false })
  styleLine = prop<StyleLine[]>({
    default: [
      {
        strokeDasharray: '',
        strokeWidth: 3,
        gradient: 'purple-blue',
        round: false
      }
    ]
  })
}

export default class GraphicDiagram extends Vue.with(Props) {
  line?: any
  tooltipX: number = 0
  tooltipY: number = 0
  tooltipCircleX: number = 0
  tooltipCircleY: number = 0
  tooltipValueX: string = ''
  tooltipValueY: any = {}
  differencePercent: number = 0
  svgWidth: number = 697
  svgHeight: number = 150
  realWidth: number = 0
  realHeight: number = 0
  isMounted: boolean = false
  isMobile: boolean = false
  show: boolean = false
  isSvg: null|HTMLElement = null
  dateActive: string = ''

  mounted () {
    const svg = this.$refs.svgContainer as unknown as HTMLElement
    this.isSvg = svg
    if (svg) {
      this.realWidth = svg.offsetWidth
      this.realHeight = svg.offsetHeight
      if (this.realWidth < 700) {
        this.svgHeight = 144
        this.isMobile = true
      }
    }
    this.isMounted = true
  }

  render () {
    const xAxisLabels = this.data.xAxisLabels
    const yAxisLabels = this.data.yAxisLabels
    const lines = this.data.lines
    const styleLine = this.styleLine

    const tooltipPosition = `top: ${this.tooltipY - 59}px; left: ${this.tooltipX}px;`

    // const width = svgWidth / xAxisLabels.length

    const svgWidth = this.svgWidth
    const svgHeight = this.svgHeight

    const sC = this.$refs.svgContainer as unknown as HTMLElement
    this.realWidth = sC?.offsetWidth
    this.realHeight = sC?.offsetHeight

    const store = useStore()
    const date = store.state.date

    if (this.isMobile) this.svgWidth = 40 * xAxisLabels.length - 16

    return (
      <div
        class={cn('segment-diagram', { mobile: this.isMobile })}
        onClick={() => this.unsetTooltipPosition()}
      >
        <div class="graphic-body absolute" style={`padding-right: ${this.showLabelY ? '34px' : '0'}`}>
          <div class="y-axis-label-wrap">{ yAxisLabels.map(label => <div class={cn('y-axis-label', { show: this.showLabelY })} style={`bottom: ${label.value}%`}>{label.label}</div>)}</div>
          { yAxisLabels.map(label => <div class="y-axis-line" style={`bottom: ${label.value}%; width: ${this.showLabelY ? 'calc(100% - 34px)' : '100%'}`}/>)}
        </div>
        <div class="graphic-body-scroll scroll-bar">
          <div class="graphic-body" style={`padding-right: ${this.showLabelY ? '34px' : '0'}`}>
            <div
              class="svg-container"
              ref="svgContainer"
              style={{
                width: this.isMobile ? 40 * xAxisLabels.length - 16 : '100%'
              }}
            >
              <svg
                width="100%"
                viewBox={`0 0 ${this.isMobile ? 40 * xAxisLabels.length - 16 : svgWidth} ${svgHeight}`}
                class="sector-diagram-figure"
                style={{
                  width: this.isMobile ? 40 * xAxisLabels.length - 16 : '100%'
                }}
              >
                { lines.map((l, idx) => {
                  // для понимания процесса надо читать, как рассчитываются промежуточные точки в кривых Безье
                  const smoothing = this.smoothing
                  const width = svgWidth / (l.points.length - 1)
                  const points = l.points.map((p: number, i: number) => [i * width, svgHeight - (p * svgHeight / 100)])

                  const line = (pointA: any, pointB: any) => {
                    const lengthX = pointB[0] - pointA[0]
                    const lengthY = pointB[1] - pointA[1]
                    return {
                      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
                      angle: Math.atan2(lengthY, lengthX)
                    }
                  }

                  const controlPoint = (current: any, previous: any, next: any, reverse: boolean = false) => {
                    const p = previous || current
                    const n = next || current
                    const o = line(p, n)

                    const angle = o.angle + (reverse ? Math.PI : 0)
                    const length = o.length * smoothing

                    const x = current[0] + Math.cos(angle) * length
                    const y = current[1] + Math.sin(angle) * length
                    return [x, y]
                  }

                  const bezier = (point: any, i: any, a: any) => {
                    if (isNaN(point[0]) || isNaN(point[1])) return
                    const cps = controlPoint(a[i - 1], a[i - 2], point)
                    const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
                    return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`
                  }

                  const d = points.reduce((acc, point, i, a) => i === 0
                    ? `M ${point[0]},${point[1]}`
                    : `${acc} ${bezier(point, i, a)}`
                  , '')

                  const dGradient = points.reduce((acc, point, i, a) => i === 0
                    ? `${acc} ${bezier(point, 1, a)}`
                    : `${acc} ${bezier(point, i, a)}`
                  , '')

                  const gradientD = () => {
                    let str = `M 0,${svgHeight}`

                    str = `${str} ${dGradient} L ${points[points.length - 1][0]},${svgHeight} Z`
                    return str
                  }

                  return (
                    <g>
                      {l.type === 'line' && <g>
                        <path
                          class="gradient"
                          d={gradientD()}
                          pointer-events="visibleStroke"
                          fill={`url(#${styleLine[idx].gradient})`}
                        />
                        <path
                          d={d}
                          pointer-events="visibleStroke"
                          fill="none"
                          stroke={l.color}
                          stroke-dasharray={styleLine[idx].strokeDasharray}
                          stroke-width={styleLine[idx].strokeWidth}
                          stroke-linecap="round"
                          onMouseenter={(e: any) => { this.setTooltipPosition(l, e, idx) }}
                          onMouseleave={this.unsetTooltipPosition}
                        />
                      </g>}
                      { l.type === 'dot' && l.points.map((y, pointsIdx) => {
                        return (
                          <g>
                            { y && <circle
                              cx={this.svgWidth / (xAxisLabels.length - 1) * pointsIdx}
                              cy={this.svgHeight - (this.svgHeight / 100 * y)}
                              r="8"
                              fill="white"
                              opacity="0.5"
                            />}
                            { y && <circle
                              style="transition: .4s;"
                              cx={this.svgWidth / (xAxisLabels.length - 1) * pointsIdx}
                              cy={this.svgHeight - (this.svgHeight / 100 * y)}
                              r="3.5"
                              fill={l.color}
                              stroke={l.color}
                              onMouseenter={(e: any) => { this.setTooltipPosition(l, e, idx) }}
                              onMouseleave={this.unsetTooltipPosition}
                            /> }
                          </g>
                        )
                      }) }

                      { this.line && <line
                        style="transition: .4s;"
                        x1={this.tooltipCircleX}
                        y1={this.svgHeight}
                        x2={this.tooltipCircleX}
                        y2={0}
                        stroke="#6553F5"
                        stroke-dasharray="6 6"
                        stroke-width="1"
                      /> }
                      {this.line && <g>
                        <g filter="url(#filter0_d_529_46215)">
                          <circle style="transition: .4s;" cx={this.tooltipCircleX} cy={this.tooltipCircleY} r="7.5" fill="white" />
                        </g>
                        <circle style="transition: .4s;" cx={this.tooltipCircleX} cy={this.tooltipCircleY} r="4.5" fill="#6553F5" />
                      </g>}

                    </g>
                  )
                }) }

                <linearGradient id="purple-blue" x1="0" y1="-400" x2="0" y2="150" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#9747FF"/>
                  <stop offset="1" stop-color="#9747FF" stop-opacity="0"/>
                </linearGradient>
                <defs>
                  <filter id="filter0_d_529_46215" x={this.tooltipCircleX - 19} y={this.tooltipCircleY - 19} width="63" height="63" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="7"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_529_46215"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_529_46215" result="shape"/>
                  </filter>
                </defs>

              </svg>
              { this.line &&
                <div
                  key="tooltip"
                  class={cn('segment-diagram-tooltip', { hide: this.isShowNullValueTooltip && Number(this.tooltipValueY) === 0 })}
                  style={tooltipPosition}>
                  <div class="segment-diagram-tooltip-inner">
                    <div class="flex flex-ai-fs">
                      <div>{this.tooltipValueY} {this.tooltipLabel}</div>
                      <div class={`difference ${this.differencePercent >= 0 ? 'success' : 'warning'}`}>{this.differencePercent > 0 ? '+' + this.differencePercent : this.differencePercent}%</div>
                    </div>
                    <small>{date ? moment(date).format('D MMM YYYY') : this.dateActive}</small>
                  </div>
                </div>
              }
            </div>
          </div>
          <div
            class="graphic-diagram-legend-bottom"
            style={{
              marginRight: this.showLabelY ? '34px' : '0',
              gridTemplateColumns: `repeat(${xAxisLabels.length}, 24px)`
            }}
          >
            { this.isMounted && xAxisLabels.map((label, i) => {
              return (
                <div
                  class={cn('x-axis-label', { hover: this.tooltipValueX === label })}
                  // style={`left: ${100 / xAxisLabels.length * i}%; width: ${100 / xAxisLabels.length}%`}
                >{label}</div>
              )
            }) }
          </div>
        </div>
        <p>{this.line}</p>
      </div>
    )
  }

  setTooltipPosition (line: any, event: any, idx: number) {
    const sC = this.$refs.svgContainer as unknown as HTMLElement
    const shift = (this.maxYValue - this.minYValue) / 100
    this.line = line

    const index = Math.trunc(this.tooltipX / sC?.offsetWidth * this.line.points.length)
    let tooltipValueYPrev = 0
    let difference = 0

    this.tooltipX = event.layerX
    this.tooltipY = event.layerY
    this.tooltipCircleX = this.tooltipX / sC?.offsetWidth * this.svgWidth
    this.tooltipCircleY = this.tooltipY / sC?.offsetHeight * this.svgHeight
    this.tooltipValueX = this.data.xAxisLabels[index]
    this.tooltipValueY = this.itemsRead[idx][index].sum // статичное значение
    if (this.itemsRead[idx][index - 1]) tooltipValueYPrev = this.itemsRead[idx][index - 1].sum
    this.dateActive = moment(this.itemsRead[idx][index].id, 'YYYY-MM-DD').format('D MMM YYYY')
    difference = this.tooltipValueY - tooltipValueYPrev
    this.differencePercent = this.tooltipValueY !== 0 ? Math.round((difference / this.tooltipValueY * 100) * 10) / 10 : 0
    // this.tooltipValueY = this.minYValue + ((this.maxYValue - this.minYValue) / sC?.offsetHeight * (sC?.offsetHeight - this.tooltipY)) // динамическое значение
    this.tooltipValueY = new Intl.NumberFormat('ru-RU').format(this.tooltipValueY.toFixed(2))
  }

  unsetTooltipPosition () {
    this.line = null
  }
}
