import { axios } from '@/axios'
import { ExpensesName, ExpensesNameMeta } from '@/core/types'
import { CustomInput } from '@/UI/input/custom-input'
import { useClickOutside, usePaginatedApi } from 'apptimizm-ui'
import { defineComponent, inject, onBeforeMount, onBeforeUnmount, PropType, Ref, ref, watch } from 'vue'
import { TSidebarState } from '../types'
import { debounce } from 'lodash'
import { useObserver } from '@/composables/use-observer'

export default defineComponent({
  props: {
    onChangeState: {
      type: Function as PropType<(state: TSidebarState) => void>,
      required: true
    },
    onChangeItem: {
      type: Function as PropType<(item: ExpensesName) => void>,
      required: true
    },
    onCreateNewExpensesName: {
      type: Function as PropType<(name: string) => void>,
      required: true
    },
    deleteIcon: {
      type: Function as PropType<() => JSX.Element>,
      default: () => {}
    }
  },
  setup (props) {
    const meta = new ExpensesNameMeta()
    const target = ref(null) as unknown as Ref<HTMLElement>
    const params: { [key: string]: string|string[] } = {}
    const lastUsedExpenses: Ref<ExpensesName[]> = ref([])
    const search: Ref<string> = ref('')
    const isMobile: Ref<boolean> = inject('isMobile', ref(false))
    const element = ref(null) as unknown as Ref<HTMLElement>
    let isSelected = false
    let countClicked = 0
    const {
      items,
      reload,
      loadNext
    } = usePaginatedApi(
      meta.endpoint,
      axios,
      ref(params),
      ref(20),
      'page',
      (x) => meta.load(x),
      true,
      'results',
      'count'
    )

    const loadLastUsed = () => {
      axios.get(meta.endpoint + 'last_used/', { params })
        .then(({ data }) => { lastUsedExpenses.value = data.map((item: ExpensesName) => meta.load(item)) })
    }

    const callback = debounce(reload, 500)

    onBeforeMount(() => {
      loadLastUsed()
      reload()
    })

    useObserver(loadNext, target)

    watch(search, to => {
      params.search = to
      callback()
    })

    const onClearLastUsed = () => {
      axios.delete(meta.endpoint + 'last_used/clear/')
        .then(loadLastUsed)
    }
    const onDeleteExpenses = (id: string) => {
      axios.delete(meta.endpoint + id + '/')
        .then(callback)
    }

    const onClose = () => {
      if (search.value.length > 1) {
        const expensesName = items.value.find((x: ExpensesName) => x.title.toLowerCase() === search.value.toLowerCase())
        if (expensesName) props.onChangeItem(expensesName)
        else props.onCreateNewExpensesName(search.value)
      }
      props.onChangeState('new-transaction')
    }

    useClickOutside(element, () => {
      if (countClicked < 1) countClicked += 1
      else onClose()
    })

    const setItem = (item: ExpensesName): void => {
      isSelected = true
      props.onChangeItem(item)
      props.onChangeState('new-transaction')
    }

    onBeforeUnmount(() => {
      if (!isSelected && search.value.length > 1) {
        const expensesName = items.value.find((x: ExpensesName) => x.title.toLowerCase() === search.value.toLowerCase())
        if (expensesName) props.onChangeItem(expensesName)
        else props.onCreateNewExpensesName(search.value)
      }
    })

    return () => <div>
      <div class='sidebar-transaction-name sidebar-extension-block sidebar-border-left' ref={element}>
        <div class='sidebar-cell head shadow'>
          <div class='flex-sb'>
            {!isMobile.value && <>
              <div class='flex'>
                <button onClick={onClose}>
                  <img src={require('@/assets/img/double-arrow.svg')} alt='' />
                </button>
                <h4 class='ml-1'>На что потратил</h4>
              </div>
              <button onClick={onClose} class={{ link: true, disabled: search.value.length < 2 }}>Сохранить</button>
            </>}
            {isMobile.value && <>
              <h4 class='ml-1'>На что потратил</h4>
              <button onClick={onClose}>
                <img src={require('@/assets/img/close.svg')} alt='' />
              </button>
            </>}
          </div>
        </div>
        <div class='sidebar-cell'>
          <CustomInput
            modelValue={search.value}
            onClearValue={() => { search.value = '' }}
            onValueChange={(v: string) => { search.value = v }}
            placeholder='Поиск'
            search={true}
          />
        </div>
        { lastUsedExpenses.value.length > 0 && <div class='sidebar-cell'>
          <div class='flex-sb'>
            <p class='text secondary-color'>Последние использованные</p>
            <button class='link text' onClick={onClearLastUsed}>Очистить</button>
          </div>
          <div class='sidebar-transaction-name-list pt-1 pb-1'>
            <div class='sidebar-scroll-container scroll-bar'>
              { lastUsedExpenses.value.map((item: ExpensesName) =>
                <p
                  class='sidebar-transaction-name-item mb-1 mt-1'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setItem(item)}
                >
                  { item.title }
                </p>
              )}
            </div>
          </div>
        </div> }
        <div class='sidebar-cell'>
          <p class='text secondary-color'>Все расходы</p>
          <div class='sidebar-transaction-name-list pt-1 pb-1'>
            <div class='sidebar-scroll-container scroll-bar'>
              {items.value.map((item: ExpensesName, idx) =>
                <div class='custom-relation-select-header'>
                  <p
                    ref={idx === items.value.length - 1 ? ref(target) : ref()}
                    class='sidebar-transaction-name-item mb-1 mt-1'
                    style={{ cursor: 'pointer', textTransform: 'capitalize' }}
                    onClick={() => setItem(item)}
                  >
                    {item.title}
                  </p>
                  {item.canDeleted && <span style={{ cursor: 'pointer' }} onClick={() => onDeleteExpenses(item.id)}>{props.deleteIcon()}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
})
