import { computed, defineComponent, inject, onBeforeMount, PropType, Ref, ref } from 'vue'
import { TSidebarState } from '../types'
import { axios } from '@/axios'
import { Accounts, AccountsMeta, ExpensesName, ExpensesNameMeta, ExpensesType, Transaction, TransactionMeta } from '@/core/types'
import { FormErrors } from 'apptimizm-ui'
import { CustomInput } from '@/UI/input/custom-input'
import { defineCustomRelationSelect } from '@/UI/custom-relation-select/custom-relation-select'
import { DatePicker } from '@/UI/date-picker/date-picker'
import ExtensionExpensesName from './extension-expenses-name'
import { CustomInputButton } from '@/UI/custom-input-button/custom-input-button'
import ExtensionExpensesType from './extension-expenses-type'
import { useToggle } from '@/composables/use-toggle'
import moment from 'moment'
import Swal from 'sweetalert2'

const ExpensesNameIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z" fill="#6553F5"/>
  <path d="M20 20L18 18" stroke="#6553F5" stroke-width="2" stroke-linecap="round"/>
</svg>

const ExpensesDeleteIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M6.41773 5.25C4.39152 5.25 3.23492 7.56321 4.45064 9.18418C4.96952 9.87601 5.25001 10.7175 5.25001 11.5823V18C5.25001 20.6234 7.37666 22.75 10 22.75H14C16.6234 22.75 18.75 20.6234 18.75 18V11.5823C18.75 10.7175 19.0305 9.87601 19.5494 9.18418C20.7651 7.56321 19.6085 5.25 17.5823 5.25H6.41773Z" fill="#6553F5"/>
  <path d="M13.9998 17L13.9998 11" stroke="#6553F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.99982 17L9.99982 11" stroke="#6553F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 6L15.4558 4.36754C15.1836 3.55086 14.4193 3 13.5585 3H10.4415C9.58066 3 8.81638 3.55086 8.54415 4.36754L8 6" stroke="#6553F5" stroke-width="1.5" stroke-linecap="round"/>
</svg>

const TransactionDeleteIcon = () => <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.025 21C6.625 21 6.275 20.85 5.975 20.55C5.675 20.25 5.525 19.9 5.525 19.5V5.25H4.5V3.75H9.2V3H15.8V3.75H20.5V5.25H19.475V19.5C19.475 19.9 19.325 20.25 19.025 20.55C18.725 20.85 18.375 21 17.975 21H7.025ZM17.975 5.25H7.025V19.5H17.975V5.25ZM9.675 17.35H11.175V7.375H9.675V17.35ZM13.825 17.35H15.325V7.375H13.825V17.35ZM7.025 5.25V19.5V5.25Z" fill="#898989"/>
</svg>

const ExpensesTypeIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02727 3C4.39575 3 3 4.36126 3 6V8.01923C3 9.77003 4.41685 10.9887 5.96397 11.0978C5.98504 11.0993 6.00615 11.1 6.02727 11.1H8.07273C9.70425 11.1 11.1 9.73874 11.1 8.1V6C11.1 4.40745 9.83161 3 8.15455 3H6.02727ZM15.9273 3C14.2957 3 12.9 4.36126 12.9 6V8.1C12.9 9.73874 14.2957 11.1 15.9273 11.1H17.9727C19.6043 11.1 21 9.73874 21 8.1V6C21 4.40745 19.7316 3 18.0545 3H15.9273ZM6.02727 12.9C4.39575 12.9 3 14.2613 3 15.9V18C3 19.6907 4.43976 20.8903 5.96397 20.9978C5.98504 20.9993 6.00615 21 6.02727 21H8.07273C9.70425 21 11.1 19.6387 11.1 18V15.9C11.1 14.3074 9.83161 12.9 8.15455 12.9H6.02727ZM15.9273 12.9C14.2957 12.9 12.9 14.2613 12.9 15.9V18C12.9 19.6387 14.2957 21 15.9273 21H17.9727C19.6043 21 21 19.6387 21 18V15.9C21 14.3074 19.7316 12.9 18.0545 12.9H15.9273Z" fill="#6553F5"/>
</svg>

const BankAccountsSelect = defineCustomRelationSelect<Accounts>()
const BankAccountsIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 5.00024L11.3224 1.19836C12.1279 0.622962 13.2599 1.03976 13.5 2.00012L9 5.00024H6ZM18.3139 2.94179L19 5.00024H10.5L15.335 1.89189C16.4327 1.18618 17.9012 1.70377 18.3139 2.94179ZM5.75 6.25005C3.67893 6.25005 2 7.92898 2 10V18C2 20.0711 3.67893 21.75 5.75 21.75H17.75C19.8211 21.75 21.5 20.0711 21.5 18V17.75H21.5001V16.5C22.6046 16.5 23.5 15.6046 23.5 14.5C23.5 13.3955 22.6046 12.5001 21.5001 12.5V11.25H21.5V10C21.5 7.92898 19.8211 6.25005 17.75 6.25005H5.75ZM21.5 11.25H20.0483H18.5001C16.7051 11.25 15.2501 12.7051 15.2501 14.5C15.2501 16.295 16.7051 17.75 18.5001 17.75H21.5V16.6924V16.5H18.5C17.3954 16.5 16.5 15.6046 16.5 14.5C16.5 13.3955 17.3954 12.5 18.5 12.5H21.5V11.3078V11.25Z" fill="#6553F5"/>
</svg>

const currencies = new Map([
  ['rubles', '₽'],
  ['dollars', '$'],
  ['euro', '€']
])

export default defineComponent({
  props: {
    selectedTransactionId: {
      type: [String, Object] as PropType<string | null>,
      default: null
    }
  },
  setup (props) {
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('default'))
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const reloadTransactions = inject('reloadTransactions', () => {})
    const isMobile: Ref<boolean> = inject('isMobile', ref(false))
    const isLoading: Ref<boolean> = ref(false)
    const transaction = ref(new Transaction())
    transaction.value.paymentDatetime = moment().format('YYYY-MM-DDThh:mm')
    const transactionMeta = new TransactionMeta()
    const errors = ref(new FormErrors({}))
    const isNewExpensesName: Ref<boolean> = ref(false)
    const createTransaction = () => {
      isLoading.value = true
      const method = props.selectedTransactionId ? 'patch' : 'post'
      const endpoint = props.selectedTransactionId ? transactionMeta.endpoint + props.selectedTransactionId + '/' : transactionMeta.endpoint
      axios[method](endpoint, transactionMeta.dump(transaction.value))
        .then(({ data }) => {
          if (data.errors) errors.value = new FormErrors(data.errors)
          else {
            onChangeState('default')
            reloadTransactions()
          }
        }).then(() => {
          window.dispatchEvent(new Event('updateAnalitics'))
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const createExpensesName = () => {
      isLoading.value = true
      const expensesName: ExpensesName = { ...transaction.value.expensesName, expensesType: transaction.value.expensesType }
      axios.post(new ExpensesNameMeta().endpoint, new ExpensesNameMeta().dump(expensesName))
        .then(({ data }) => {
          if (data.errors) errors.value = new FormErrors(data.errors)
          else {
            useToggle(isNewExpensesName)
            transaction.value.expensesName = new ExpensesNameMeta().load(data)
            createTransaction()
          }
        })
    }

    const submit = () => {
      if (parseInt(transaction.value.amount) > 0) {
        if (isLoading.value) return
        if (isNewExpensesName.value) createExpensesName()
        else createTransaction()
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Сумма должна быть больше нуля!'
        })
      }
    }

    const onDeleteTransaction = () => {
      axios.delete(transactionMeta.endpoint + props.selectedTransactionId + '/')
        .then(() => {
          onChangeState('default')
          reloadTransactions()
        })
        .then(() => {
          window.dispatchEvent(new Event('updateAnalitics'))
        })
    }

    const disabled = computed(() => {
      if (!transaction.value.bankAccont.id) return true
      if (!transaction.value.amount) return true
      if (!transaction.value.expensesType.id) return true
      if (!transaction.value.paymentDatetime) return true
      if (isLoading.value) return true

      return false
    })

    onBeforeMount(() => {
      if (!props.selectedTransactionId) return

      axios.get(transactionMeta.endpoint + props.selectedTransactionId + '/')
        .then(({ data }) => { transaction.value = transactionMeta.load(data) })
        .catch(() => { onChangeState('default') })
    })

    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    })

    return () => <div class='sidebar-transaction sidebar-wrapper'>
      <>
        { sidebarState.value === 'new-transaction-name-extension' && <ExtensionExpensesName
          onChangeState={onChangeState}
          onChangeItem={(item: ExpensesName) => {
            transaction.value.expensesName = item
            isNewExpensesName.value = false
            transaction.value.expensesType = item.expensesType
          }}
          onCreateNewExpensesName={(name: string) => {
            transaction.value.expensesName.title = name
            useToggle(isNewExpensesName)
          }}
          deleteIcon={ExpensesDeleteIcon}
        /> }
        { sidebarState.value === 'new-transaction-type-extension' && <ExtensionExpensesType
          onChangeState={onChangeState}
          onChangeItem={(item: ExpensesType) => { transaction.value.expensesType = item }}
        /> }
      </>
      {(!isMobile.value || sidebarState.value === 'new-transaction') && <div class='sidebar-border-left'>
        <div class='sidebar-cell shadow head flex-sb'>
          <h4>Информация о вашем расходе</h4>
          { isMobile.value && <button onClick={() => onChangeState('default')} >
            <img src={require('@/assets/img/close.svg')} alt='' />
          </button> }
        </div>
        <div class='sidebar-cell'>
          <CustomInput
            modelValue={transaction.value.amount}
            onValueChange={(v: string) => { transaction.value.amount = v }}
            placeholder='0.0'
            errors={errors.value.forField('amount')}
            isCurency={true}
            curencyType={currencies.get(transaction.value.bankAccont.currency.symbol) || currencies.get('rubles')}
          />
          <div class='mt-2'>
            <CustomInputButton
              modelValue={transaction.value.expensesName.title}
              active={sidebarState.value === 'new-transaction-name-extension'}
              onChange={() => onChangeState('new-transaction-name-extension')}
              onCreateNewExpensesName={(name: string) => {
                transaction.value.expensesName.title = name
                isNewExpensesName.value = true
              }}
              placeholder='На что потратили (необязательно)'
              icon={ExpensesNameIcon}
              errors={errors.value.forField('expenses_name')}
              expenseName={true}
            />
          </div>
          <div class='mt-2'>
            <CustomInputButton
              modelValue={transaction.value.expensesType.title}
              active={sidebarState.value === 'new-transaction-type-extension'}
              onChange={() => onChangeState('new-transaction-type-extension') }
              placeholder='Тип расхода'
              icon={ExpensesTypeIcon}
              errors={errors.value.forField('expenses_type')}
            />
          </div>
          <div class='mt-2'>
            <BankAccountsSelect
              endpoint={new AccountsMeta().endpoint}
              modelValue={transaction.value.bankAccont}
              onValueChange={(v) => { transaction.value.bankAccont = v }}
              placeholder='Откуда была произведена трата'
              axios={axios}
              itemConverter={(v) => v}
              icon={BankAccountsIcon}
              errors={errors.value.forField('bank_account')}
            />
          </div>
          <div class='mt-2'>
            <DatePicker
              placeholder='Когда потратили'
              modelValue={transaction.value.paymentDatetime}
              onValueChange={v => { transaction.value.paymentDatetime = v }}
              errors={errors.value.forField('payment_datetime')}
            />
          </div>
          <div class='mt-2'>
            <p class='desc mb-1'>Место для вашей заметки...</p>
            <textarea
              class='default-textarea'
              placeholder='Текст...'
              value={transaction.value.note}
              onInput={(e: Event) => { transaction.value.note = (e.target as HTMLInputElement).value }}
            />
            { errors.value.forField('note') && <p class='error-text text mt-1'>{ errors.value.forField('note') }</p> }
          </div>
        </div>
        <div class='sidebar-cell primary-grid-2'>
          <button class='default-button full-width' onClick={() => onChangeState('default')}>Отменить</button>
          <button class='primary-button full-width' disabled={disabled.value} onClick={submit}>Сохранить</button>
        </div>
        {props.selectedTransactionId && <div onClick={() => onDeleteTransaction()} class='sidebar-cell' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 14, color: '#898989', fontSize: '15px', fontWeight: 300, cursor: 'pointer' }}>{TransactionDeleteIcon()} Удалить расход</div>}
      </div>}
    </div>
  }
})
