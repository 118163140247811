import { computed, defineComponent, PropType, ref, Ref } from 'vue'
import './custom-input.sass'

export const CustomInput = defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true
    },
    onValueChange: {
      type: Function as PropType<(v: string) => void>,
      required: true
    },
    placeholder: String,
    password: Boolean,
    onBlur: Function as PropType<(v: string) => void>,
    onRegExpFail: Function as PropType<(v: string) => void>,
    disabled: Boolean,
    maxLength: Number,
    regExp: Object as PropType<RegExp>,
    regExpError: {
      type: String,
      default: 'Неверный формат данных'
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    onClearValue: {
      type: Function as PropType<() => void>,
      default: () => {}
    },
    search: {
      type: Boolean,
      default: false
    },
    isCurency: {
      type: Boolean,
      default: false
    },
    curencyType: {
      type: String,
      default: '₽'
    }
  },
  setup (props, context) {
    const errors: Ref<Set<string>> = ref(new Set())

    const onBlur = (e: Event) => {
      context.emit('blur')
      props.onBlur && props.onBlur((e.target as HTMLInputElement).value)
      validateRegExp((e.target as HTMLInputElement).value)
    }

    const validateRegExp = (value: string) => {
      if (!props.regExp) return
      props.regExp.test(value)
        ? errors.value.delete(props.regExpError)
        : errors.value.add(props.regExpError)
    }

    const allErrors = computed(() => [...errors.value, ...props.errors])

    return () => {
      return (
        <label>
          <div class={{ 'custom-input': true, 'with-error': allErrors.value.length }}>
            {props.search && <span class='mr-1'>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="7.33333" cy="7.33333" rx="5.33333" ry="5.33333" stroke="#CDCDCD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11 11.3047L14.3333 14.638" stroke="#CDCDCD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>}
            <input
              disabled={Boolean(props.disabled)}
              type={props.password ? 'password' : props.isCurency ? 'number' : 'text'}
              value={props.modelValue}
              onInput={(e: Event) => { props.onValueChange((e.target as HTMLInputElement).value) }}
              onBlur={onBlur}
              onFocusout={onBlur}
              placeholder={props.placeholder}
              maxlength={props.maxLength}
            />
            {(!props.search && !props.isCurency) && <button class='custom-input-clear' onClick={props.onClearValue}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.92742 4.92892C1.02419 8.83215 1.02419 15.1678 4.92742 19.0711C8.83065 22.9743 15.1663 22.9743 19.0696 19.0711C22.9728 15.1678 22.9728 8.83215 19.0696 4.92892C15.1663 1.02569 8.83065 1.02569 4.92742 4.92892ZM16.2411 9.17156L13.4127 12L16.2411 14.8284L14.8269 16.2426L11.9985 13.4142L9.17006 16.2426L7.75585 14.8284L10.5843 12L7.75585 9.17156L9.17006 7.75734L11.9985 10.5858L14.8269 7.75734L16.2411 9.17156Z"
                  fill={ props.modelValue.length ? '#6553F5' : '#E0E0E0' }
                />
              </svg>
            </button>}
            {props.search && <button class='custom-input-clear' onClick={props.onClearValue}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z" fill="#CDCDCD"/>
              </svg>
            </button>}
            {props.isCurency && <div class='cusntom-input-curency flex-center'>
              { props.curencyType }
            </div>}
          </div>
          {allErrors.value.map((error) => (
            <div class='error-text text mt-1'>{error}</div>
          ))}
        </label>
      )
    }
  }
})
