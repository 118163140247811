import { Accounts } from '@/core/types'
import { RadioButton } from '@/UI/radio-button'
import { defineComponent, inject, PropType } from 'vue'

const AccountIcon = () =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5.00018L11.3224 1.1983C12.1279 0.622901 13.2599 1.03969 13.5 2.00006L9 5.00018H6ZM18.3139 2.94173L19 5.00018H10.5L15.335 1.89183C16.4327 1.18612 17.9012 1.70371 18.3139 2.94173ZM5.75 6.24999C3.67893 6.24999 2 7.92892 2 9.99999V18C2 20.0711 3.67893 21.75 5.75 21.75H17.75C19.8211 21.75 21.5 20.0711 21.5 18V17.75H21.5001V16.5C22.6046 16.5 23.5 15.6045 23.5 14.5C23.5 13.3954 22.6046 12.5 21.5001 12.5V11.25H21.5V9.99998C21.5 7.92892 19.8211 6.24999 17.75 6.24999H5.75ZM21.5 11.25H20.0483H18.5001C16.7051 11.25 15.2501 12.7051 15.2501 14.5C15.2501 16.2949 16.7051 17.75 18.5001 17.75H21.5V16.6923V16.5H18.5C17.3954 16.5 16.5 15.6046 16.5 14.5C16.5 13.3954 17.3954 12.5 18.5 12.5H21.5V11.3077V11.25Z"
      fill='#fff'
    />
  </svg>

const SharedAccountIcon = () => {
  return (
    <div>
      <svg style={{ position: 'absolute', top: 10, right: 10 }} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_393_17846)">
          <path d="M3.30351 5.14907C3.30351 5.54907 3.40351 5.94907 3.55351 6.29907H1.50351C1.20351 6.29907 0.903512 6.14907 0.753512 5.89907C0.603512 5.59907 0.553512 5.29907 0.653512 5.04907C0.853512 4.54907 1.25351 4.14907 1.70351 3.89907C1.50351 3.64907 1.35351 3.29907 1.35351 2.94907C1.35351 2.19907 2.00351 1.54907 2.75351 1.54907C3.55351 1.54907 4.15351 2.19907 4.15351 2.94907C4.15351 2.99907 4.15351 3.09907 4.15351 3.14907C3.65351 3.64907 3.30351 4.34907 3.30351 5.14907Z" fill="white"/>
          <path d="M11.4535 5.84902C11.3035 6.09902 11.0035 6.24902 10.7035 6.24902H8.65351C8.80351 5.89902 8.90351 5.49902 8.90351 5.09902C8.90351 4.29902 8.60351 3.59902 8.05351 3.09902C8.05351 3.04902 8.05351 2.94902 8.05351 2.89902C8.05351 2.09902 8.70351 1.49902 9.45351 1.49902C10.2035 1.49902 10.8535 2.14902 10.8535 2.89902C10.8535 3.24902 10.7035 3.59902 10.5035 3.84902C10.9535 4.14902 11.3035 4.54902 11.5535 5.04902C11.6535 5.29902 11.6035 5.59902 11.4535 5.84902Z" fill="white"/>
          <path d="M7.30352 6.74912C7.80352 6.39912 8.15352 5.79912 8.15352 5.09912C8.15352 3.99912 7.25352 3.09912 6.15352 3.09912C5.05352 3.09912 4.10352 4.04912 4.10352 5.14912C4.10352 5.79912 4.45352 6.39912 4.95352 6.79912C4.05352 7.09912 3.35352 7.79912 2.95352 8.69912C2.80352 9.04912 2.85352 9.44912 3.05352 9.79912C3.30352 10.1491 3.65352 10.3491 4.05352 10.3491H8.15352C8.55352 10.3491 8.95352 10.1491 9.15352 9.79912C9.35352 9.44912 9.40352 9.04912 9.25352 8.69912C8.90352 7.74912 8.15352 7.09912 7.30352 6.74912Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_393_17846">
            <rect width="12" height="12" fill="white" transform="translate(0.103516 0.0490723)"/>
          </clipPath>
        </defs>
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 5.00018L11.3224 1.1983C12.1279 0.622901 13.2599 1.03969 13.5 2.00006L9 5.00018H6ZM18.3139 2.94173L19 5.00018H10.5L15.335 1.89183C16.4327 1.18612 17.9012 1.70371 18.3139 2.94173ZM5.75 6.24999C3.67893 6.24999 2 7.92892 2 9.99999V18C2 20.0711 3.67893 21.75 5.75 21.75H17.75C19.8211 21.75 21.5 20.0711 21.5 18V17.75H21.5001V16.5C22.6046 16.5 23.5 15.6045 23.5 14.5C23.5 13.3954 22.6046 12.5 21.5001 12.5V11.25H21.5V9.99998C21.5 7.92892 19.8211 6.24999 17.75 6.24999H5.75ZM21.5 11.25H20.0483H18.5001C16.7051 11.25 15.2501 12.7051 15.2501 14.5C15.2501 16.2949 16.7051 17.75 18.5001 17.75H21.5V16.6923V16.5H18.5C17.3954 16.5 16.5 15.6046 16.5 14.5C16.5 13.3954 17.3954 12.5 18.5 12.5H21.5V11.3077V11.25Z"
          fill='#fff'
        />
      </svg>
    </div>
  )
}

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Accounts>,
      required: true
    },
    selectedAccountId: {
      type: String,
      default: ''
    },
    base: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const onChangeSelectedAccount = inject('onChangeSelectedAccount', (value: Accounts) => value)
    // const currencies = new Map([
    //   ['rubles', '₽'],
    //   ['dollars', '$'],
    //   ['euro', '€']
    // ])

    return () => <div class='accounts-card' style={{ backgroundColor: props.item.iconBackgroundColor }}>
      <div class='accounts-card-head flex-sb'>
        <div class='flex mr-1'>
          <RadioButton
            modelValue={props.item.id === props.selectedAccountId}
            onValueChange={() => onChangeSelectedAccount(props.item)}
            placeholder={props.item.title}
          />
        </div>
        { props.item.isShared ? SharedAccountIcon() : AccountIcon() }
      </div>
      <div class='accounts-card-body' style={props.base ? { height: '40px' } : {}}>
        <div class='flex-sb mt-1'>
          {!props.base && <>
            <p class='text'>Баланс:</p>
            <p class='text'>СКОРО</p>
          </>}
        </div>
        <div class='flex-sb mt-1'>
          {!props.base && <>
            <p class='text'>Расход:</p>
            <p class='text'>{props.item.totalAmount} {props.item.currency.symbol || props.item.currency.code}</p>
          </>}
        </div>
      </div>
    </div>
  }
})
