import { Accounts } from '@/core/types'
import { defineComponent, inject, PropType } from 'vue'
import { TSidebarState } from '../../types'

const AccountIcon = (color: string) =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5.00018L11.3224 1.1983C12.1279 0.622901 13.2599 1.03969 13.5 2.00006L9 5.00018H6ZM18.3139 2.94173L19 5.00018H10.5L15.335 1.89183C16.4327 1.18612 17.9012 1.70371 18.3139 2.94173ZM5.75 6.24999C3.67893 6.24999 2 7.92892 2 9.99999V18C2 20.0711 3.67893 21.75 5.75 21.75H17.75C19.8211 21.75 21.5 20.0711 21.5 18V17.75H21.5001V16.5C22.6046 16.5 23.5 15.6045 23.5 14.5C23.5 13.3954 22.6046 12.5 21.5001 12.5V11.25H21.5V9.99998C21.5 7.92892 19.8211 6.24999 17.75 6.24999H5.75ZM21.5 11.25H20.0483H18.5001C16.7051 11.25 15.2501 12.7051 15.2501 14.5C15.2501 16.2949 16.7051 17.75 18.5001 17.75H21.5V16.6923V16.5H18.5C17.3954 16.5 16.5 15.6046 16.5 14.5C16.5 13.3954 17.3954 12.5 18.5 12.5H21.5V11.3077V11.25Z"
      fill={color}
    />
  </svg>

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Accounts>,
      required: true
    },
    isEdited: {
      type: Boolean,
      default: false
    },
    onChangeState: {
      type: Function as PropType<(state: TSidebarState, id?: string) => void>,
      required: true
    },
    slectedAccountId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const onChangeSelectedAccount = inject('onChangeSelectedAccount', (value: Accounts) => value)
    const handler = () => {
      if (props.isEdited) return props.onChangeState('settings-accounts-edit', props.item.id)
      onChangeSelectedAccount(props.item)
      props.onChangeState('default')
    }

    return () => <div
      onClick={handler}
      class={{ 'sidebar-cell sidebar-accounts-card with-dashed-line flex-sb': true, edited: props.isEdited }}
      style={{ color: props.item.iconBackgroundColor }}
    >
      <div class='flex'>
        { props.isEdited && <div class='sidebar-accounts-card-drag mr-1'>
          <img src={require('@/assets/img/drag-icon.svg')} alt='' />
        </div> }
        { AccountIcon(props.item.iconBackgroundColor) }
        <p class='ml-1'>{props.item.title}</p>
      </div>
      { !props.isEdited && props.item.id === props.slectedAccountId &&
        <img src={require('@/assets/img/check.svg')} alt='' /> }
      { props.isEdited && <div class='sidebar-accounts-card-arrow'>
        <img src={require('@/assets/img/arrow.svg')} alt='' />
      </div> }
    </div>
  }
})
