import './segment-diagram.sass'
import { defineComponent, ref, Ref, PropType, watch, onMounted } from 'vue'
import { TransactionAnalyticsByCategory, Expense, HistogramDay } from '../types'
import { Segment, Info } from './types'
import { CategorySetting } from '@/core/types'
import SegmentDiagram from './segment-diagram'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TransactionAnalyticsByCategory[]>,
      required: true
    }
  },
  setup (props) {
    const colors: Ref<string[]> = ref([])
    const info: Ref<Info> = ref(new Info())
    const categories: Ref<CategorySetting[]> = ref([])
    const data: Ref<Segment[]> = ref([])

    onMounted(() => {
      reload()
    })

    watch(() => props.items, () => {
      reload()
    })

    const reload = async () => {
      await generateDays()
    }

    const generateDays = () => {
      categories.value = []
      const daysTemplate: HistogramDay[] = []
      props.items.forEach((item: TransactionAnalyticsByCategory) => {
        item.expenses.forEach((expense: Expense) => {
          const val: HistogramDay = new HistogramDay()
          val.date = expense.date
          val.amount = expense.totalAmount
          val.background = item.categorySetting.backgroundColor
          val.category = item.categorySetting.category.name
          daysTemplate.push(val)
        })
        categories.value.push(item.categorySetting)
      })

      generateData(daysTemplate)
    }

    const generateData = (items: HistogramDay[]) => {
      colors.value = []
      data.value = []
      const values: Segment[] = []
      categories.value.forEach(category => {
        let sum = 0
        const arr = items.filter(i => i.category === category.category.name)
        arr.forEach(i => { sum += i.amount })
        values.push({ id: category.category.id, name: category.category.name, value: sum })
        colors.value.push(category.backgroundColor)
      })

      let total = 0
      values.forEach(i => { total += i.value })
      data.value = values.map(i => {
        return {
          id: i.id,
          name: i.name,
          value: i.value / total * 100,
          sum: i.value
        }
      }).reverse()
      // info.value.titleSecond = data.value.find(i => i.name === 'Динамичные')?.value + '%'
      info.value.total = 100
    }

    return () => <div class="segment-diagram-generate">
      <SegmentDiagram
        indicatorInfo={info.value}
        indicatorData={data.value}
        size={130}
        strokeWidth={13}
        colors={[...colors.value].reverse()}
      />
      <div class="legend mt-3">
        {categories.value.map((item, idx) => {
          return (
            <div class="legend-item">
              <div
                class="legend-item-circle"
                style={{
                  background: colors.value[idx]
                }}
              ></div>
              <div class="legend-item-title">{item.category.name}</div>
            </div>
          )
        })}
      </div>
    </div>
  }
})
