import './accordion.sass'
import { useToggle } from '@/composables/use-toggle'
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

interface AccordionSlots {
  head: () => JSX.Element
  body: () => JSX.Element
}

export interface AccordionExposed {
  isOpen: boolean
}

export default defineComponent({
  props: {
    slots: {
      type: Object as PropType<AccordionSlots>,
      required: true
    }
  },
  setup (props, { expose }) {
    const isOpen: Ref<boolean> = ref(false)
    expose({ isOpen })

    return () => <div class={{ 'default-accordion': true, open: isOpen.value }}>
      <div class='default-accordion-head flex-sb' onClick={() => useToggle(isOpen)}>
        { props.slots.head() }
        <div class='default-accordion-arrow'>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 8.295L12 12.875L16.59 8.295L18 9.705L12 15.705L6 9.705L7.41 8.295Z" fill="#383838"/>
          </svg>
        </div>
      </div>
      { isOpen.value && <div class='default-accordion-body'>
        { props.slots.body() }
      </div> }
    </div>
  }
})
