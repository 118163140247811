import './chart.sass'
import { defineComponent, ref, Ref, PropType, onBeforeMount, watch, computed } from 'vue'
import GraphicGenerate from './graphic-diagram-generate.vue'
import { axios } from '@/axios'
import { errorPush } from '@/core/ErrorPopup/func'
import {
  TransactionAnalytics,
  TransactionAnalyticsMeta,
  TransactionTotalAmount,
  TransactionTotalAmountMeta,
  Expense
} from '../types'
import { useStore } from 'vuex'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

export default defineComponent({
  props: {
    defaultFilter: {
      type: Object as PropType<{[key: string]: string }>,
      required: true
    },
    isYear: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const transactionAnalytics: Ref<TransactionAnalytics[]> = ref([])
    const transactionAnalyticsDays: Ref<TransactionAnalytics[]> = ref([])
    const transactionTotalAmount: Ref<TransactionTotalAmount> = ref(new TransactionTotalAmount())

    const store = useStore()
    const date = computed(() => {
      return store.state.date
    })
    const expenses = computed(() => {
      return store.state.expenses
    })
    const setTotal = (total: number) => {
      store.commit('setTotal', total)
    }

    const getTransactionAnalytics = async () => {
      const params = { ...props.defaultFilter }
      if (date.value) params.date = date.value.format('YYYY-MM-DD')
      else return
      if (params.period) delete params.period

      await axios.get(new TransactionAnalyticsMeta().endpoint, { params }).then(result => {
        if (result?.status === 200) {
          transactionAnalytics.value = result.data.map((i: any) => new TransactionAnalyticsMeta().load(i))
        } else if (result?.data?.errors) {
          errorPush(result)
        }
      })
    }

    const getTransactionTotalAmount = async () => {
      const params = { ...props.defaultFilter }
      if (params.date) delete params.date

      await axios.get(new TransactionTotalAmountMeta().endpoint, { params }).then(result => {
        if (result?.status === 200) {
          transactionTotalAmount.value = new TransactionTotalAmountMeta().load(result.data)
          setTotal(transactionTotalAmount.value.totalAmount)
        } else if (result?.data?.errors) {
          errorPush(result)
        }
      })
    }

    onBeforeMount(() => {
      load()
    })

    watch(() => props.defaultFilter, () => {
      load()
    })

    watch(() => date.value, () => {
      load()
    })

    watch(() => expenses.value, () => {
      generateTransactionAnalyticsDays()
    })

    const load = async () => {
      await getTransactionAnalytics()
      await getTransactionTotalAmount()
    }

    const generateTransactionAnalyticsDays = () => {
      if (!expenses.value.length) return

      transactionAnalyticsDays.value = []
      const days: string[] = []

      expenses.value.forEach((i: Expense) => {
        if (!days.find(d => d === i.date)) days.push(i.date)
      })

      days.forEach(d => {
        const arr = expenses.value.filter((i: Expense) => i.date === d)
        let total = 0
        arr.forEach((i: Expense) => { total += i.totalAmount })
        transactionAnalyticsDays.value.push({
          id: d,
          date: props.isYear ? Number(moment(d, 'YYYY-MM-DD').format('M')) : Number(moment(d, 'YYYY-MM-DD').format('D')),
          sum: total
        })
      })
    }

    return () => <div class='box-white chart'>
      <h3 class="mb-1">Анализ расходов</h3>
      <div class="flex flex-ai-fs mb-3">
        <div class="total mr-1">{new Intl.NumberFormat('ru-RU').format(transactionTotalAmount.value.totalAmount)}</div>
        <div class={`difference ${transactionTotalAmount.value.percent >= 0 ? 'success' : 'warning'}`}>{transactionTotalAmount.value.percent > 0 ? '+' + transactionTotalAmount.value.percent : transactionTotalAmount.value.percent}%</div>
      </div>
      {date.value && transactionAnalytics.value.length > 0 && <GraphicGenerate items={transactionAnalytics.value} labelFormat={'hour'} />}
      {!date.value && transactionAnalyticsDays.value.length > 0 && <GraphicGenerate
        items={transactionAnalyticsDays.value}
        labelFormat={props.isYear ? 'months' : 'days'}
      />}
    </div>
  }
})
