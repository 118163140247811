import './accounts.sass'
import { Accounts, BaseAccount } from '@/core/types'
import { defineComponent, inject, PropType, Ref, ref, watch } from 'vue'
import AccountsCard from './accounts-card'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { TSidebarState } from '../sidebar/types'

const ArrowIcon = () =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0303 18.5303C14.7374 18.8232 14.2626 18.8232 13.9697 18.5303L7.96967 12.5303C7.67678 12.2374 7.67678 11.7626 7.96967 11.4697L13.9697 5.46967C14.2626 5.17678 14.7374 5.17678 15.0303 5.46967C15.3232 5.76256 15.3232 6.23744 15.0303 6.53033L9.56066 12L15.0303 17.4697C15.3232 17.7626 15.3232 18.2374 15.0303 18.5303Z" fill="#898989"/>
  </svg>

const PlusIcon = () =>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0311 4.25C12.4453 4.25054 12.7806 4.58677 12.7801 5.00098L12.7617 19.001C12.7612 19.4152 12.4249 19.7505 12.0107 19.75C11.5965 19.7495 11.2612 19.4132 11.2617 18.999L11.2801 4.99902C11.2806 4.5848 11.6168 4.24946 12.0311 4.25Z" fill="#6553F5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill="#6553F5"/>
  </svg>

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<Accounts>>,
      required: true
    },
    selectedAccount: {
      type: Object as PropType<Accounts>,
      required: true
    }
  },
  setup: function (props) {
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const onChangeSelectedAccount = inject('onChangeSelectedAccount', (value: Accounts) => value)
    // const isMobile: Ref<boolean> = inject('isMobile', ref(false))
    const activeSlideIdx = ref(0)
    const selectedAccount: Ref<Accounts> = inject('selectedAccount', ref(new Accounts()))

    const getTranslate = (idx: number, wrapper: HTMLElement): number => {
      const slides = ([...document.querySelectorAll('.swiper-slide')])
      const prevSlidesWIdth = (slides.slice(0, idx)).reduce((acc, el) => acc + el.clientWidth + 16, 0)

      if (idx < 3) return 0
      return prevSlidesWIdth - (wrapper.clientWidth / 2)
    }

    watch(selectedAccount, to => {
      activeSlideIdx.value = (props.items.findIndex(x => x.id === to.id) + 1)
      const wrapper = document.querySelector('.swiper-wrapper') as HTMLElement | undefined
      if (wrapper) wrapper.style.transform = `translate3d(${-getTranslate(activeSlideIdx.value, wrapper)}px, 0px, 0px)`
    })

    const onChangeAccount = (action: 'next' | 'prev') => {
      if (action === 'next') {
        if (activeSlideIdx.value === props.items.length) return
        onChangeSelectedAccount(props.items[activeSlideIdx.value])
      } else {
        if (activeSlideIdx.value === 0) return
        if (activeSlideIdx.value === 1) onChangeSelectedAccount(BaseAccount)
        else onChangeSelectedAccount(props.items[activeSlideIdx.value - 2])
      }
    }

    return () => <div class='accounts screen'>
      <div class='flex-sb head'>
        <h4>Счета</h4>
        <div class='slider-buttons flex'>
          <button class='slider-button flex-center left' onClick={() => onChangeAccount('prev')}>{ArrowIcon()}</button>
          <button class='slider-button flex-center right' onClick={() => onChangeAccount('next')}>{ArrowIcon()}</button>
        </div>
        <button class='button-add-account-mobile secondary-color'
          onClick={() => onChangeState('settings-accounts-new')}>
          <span>Новый</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
              fill="#898989"/>
          </svg>
        </button>
      </div>
      <div class='slider-wrapper flex'>
        <div class='accounts-list'>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={16}
          >
            <SwiperSlide>
              <AccountsCard item={BaseAccount} selectedAccountId={props.selectedAccount.id} base={true}/>
            </SwiperSlide>
            {props.items.map(item =>
              <SwiperSlide>
                <AccountsCard item={item} selectedAccountId={props.selectedAccount.id}/>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <button onClick={() => {
          onChangeState('settings-accounts-new')
        }} class='button-add-account flex-center'>
          {PlusIcon()}
        </button>
      </div>
    </div>
  }
})
