import './currencies-select.sass'
import { CurrencyItem } from '@/core/types'
import { computed, defineComponent, PropType, Ref, ref, Transition } from 'vue'
import { useToggle } from '@/composables/use-toggle'

export const CurrenciesSelect = defineComponent({
  props: {
    items: {
      type: Array as PropType<Array<CurrencyItem>>,
      required: true
    },
    modelValue: {
      type: Object as PropType<CurrencyItem>,
      required: true
    },
    onValueChange: {
      type: Function as PropType<(v: CurrencyItem) => void>,
      required: true
    },
    errors: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup (props) {
    const isOpen: Ref<boolean> = ref(false)
    const errors: Ref<Set<string>> = ref(new Set())
    const allErrors = computed(() => [...errors.value, ...props.errors])

    return () => <div>
      <div class={{ 'currencies-select': true, open: isOpen.value }}>
        <div class='currencies-select-plceholder desc'>{ props.modelValue.title || 'Валюта счета' }</div>
        <div class='currencies-select-select'>
          <div class='currencies-select-head flex' onClick={() => useToggle(isOpen)}>
            <div class='currencies-select-icon'>
              {props.modelValue.symbol}{/* <img src={require(`@/assets/img/${props.modelValue.symbol}.svg`)} alt='' /> */}
            </div>
            <div class='currencies-select-arrow'>
              <img src={require('@/assets/img/arrow.svg')} alt='' />
            </div>
          </div>
          <Transition
            appear
            duration={-1000}
            enterToClass='select-enter'
            enterActiveClass='select-enter'
            leaveToClass='select-out'
            leaveActiveClass='select-out'
          >
            { isOpen.value && <div class='currencies-select-options'>
              {props.items.map(item => <div
                class={{ 'currencies-select-option flex': true, selected: props.modelValue.id === item.id }}
                onClick={() => { props.onValueChange(item); useToggle(isOpen) }}
              >
                <div class='currencies-select-icon mr-1'>
                  {item.symbol}
                  {/* <img src={require(`@/assets/img/${item.symbol}.svg`)} alt='' /> */}
                </div>
                <div>{item.title}</div>
              </div>)}
            </div> }
          </Transition>
        </div>
      </div>
      {allErrors.value.map((error) => (
        <div class='error-text text mt-1'>{error}</div>
      ))}
    </div>
  }
})
