import { useToggle } from '@/composables/use-toggle'
import { CategorySetting } from '@/core/types'
import { defineComponent, inject, Ref, ref } from 'vue'
import { TSidebarState } from '../../types'
import Category from './category'
import CategoryList from './category-list'
import ExpensesTypeCreate from './expenses-type-create'

export default defineComponent({
  setup () {
    const categorySettings: Ref<Array<CategorySetting>> = inject('categorySettings', ref([]))
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('settings-category-list'))
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const selectedCategory: Ref<CategorySetting> = ref(new CategorySetting())
    const isCreatingExpensesType: Ref<boolean> = ref(false)

    return () => <>
      { sidebarState.value === 'settings-category-list' && <CategoryList
        items={categorySettings.value}
        onChangeSelectedCategory={(c: CategorySetting) => { selectedCategory.value = c; onChangeState('settings-category-item') }}
      /> }
      { sidebarState.value === 'settings-category-item' && !isCreatingExpensesType.value && <Category
        itemId={selectedCategory.value.id}
        onChangeState={onChangeState}
        onCreatingExpensesType={() => useToggle(isCreatingExpensesType) }
      /> }
      { sidebarState.value === 'settings-category-item' && isCreatingExpensesType.value && <ExpensesTypeCreate
        onCreatingExpensesType={() => useToggle(isCreatingExpensesType) }
        categoryType={selectedCategory.value.category.value}
      /> }
    </>
  }
})
