import './histogram.sass'
import { defineComponent, ref, Ref, PropType, watch, onMounted } from 'vue'
import { TransactionAnalyticsByExpensesType, Expense, HistogramDay, HistogramYAxisLabel } from '../types'
import { EnumType } from '@/core/types'
import Histogram from './graphic-histogram'
import moment, { Moment } from 'moment'
moment.locale('ru')

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TransactionAnalyticsByExpensesType[]>,
      required: true
    },
    isYear: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props) {
    const xAxisLabels: Ref<EnumType[]> = ref([])
    const yAxisLabels: Ref<HistogramYAxisLabel[]> = ref([
      { label: '', value: 0 },
      { label: '', value: 25 },
      { label: '', value: 50 },
      { label: '', value: 75 },
      { label: '', value: 100 }
    ])
    const days: Ref<HistogramDay[][]> = ref([])
    const maxValue = ref(100)
    const isShow = ref(false)

    onMounted(() => {
      reload()
    })

    watch(() => props.items, () => {
      reload()
    })

    const reload = async () => {
      isShow.value = false
      await generateXAxisLabels()
      await generateDays()
      isShow.value = true
    }

    const generateXAxisLabels = () => {
      if (!props.items[0].expenses.length) return
      xAxisLabels.value = []
      xAxisLabels.value = props.items[0].expenses.map((i: Expense) => {
        return {
          id: i.date,
          name: props.isYear ? moment(i.date, 'YYYY-MM-DD').format('MMM') : moment(i.date, 'YYYY-MM-DD').format('D'),
          value: i.date
        }
      })
    }

    const generateYAxisLabels = () => {
      for (let i = 0; i < yAxisLabels.value.length; i++) {
        const label = Number((maxValue.value / 100 * yAxisLabels.value[i].value))
        yAxisLabels.value[i].label = label > 1000 ? (label / 1000).toFixed(1) + ' т' : label.toFixed(0)
      }
    }

    const generateDays = () => {
      days.value = []
      const daysTemplate: HistogramDay[] = []
      props.items.forEach((item: TransactionAnalyticsByExpensesType) => {
        item.expenses.forEach((expense: Expense) => {
          const val: HistogramDay = new HistogramDay()
          val.date = expense.date
          val.amount = expense.totalAmount
          val.background = item.expensesType.backgroundColor
          val.category = item.expensesType.title
          daysTemplate.push(val)
        })
      })

      const amounts = daysTemplate.map(i => i.amount)
      maxValue.value = Math.max(...amounts)
      generateYAxisLabels()

      xAxisLabels.value.forEach((d: EnumType) => {
        const day = daysTemplate.filter((i: HistogramDay) => i.date === d.value)
        days.value.push(day)
      })
    }

    return () => isShow.value &&
      <Histogram
        xAxisLabels={xAxisLabels.value}
        yAxisLabels={yAxisLabels.value}
        days={days.value}
        max={maxValue.value}
        isSort={true}
      />
  }
})
