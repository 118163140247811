import { Transaction } from '@/core/types'
import { defineComponent, inject, ref, PropType, Ref } from 'vue'
import { TSidebarState } from '../types'
import TransactionCard from './transaction-card'
import moment from 'moment'
import { useScrollPagination } from 'apptimizm-ui'
import { useColorFilter } from '@/composables/use-color-filter'

export default defineComponent({
  props: {
    onSetTransactionId: {
      type: Function as PropType<(id: string) => void>,
      required: true
    }
  },
  setup (props, { slots }) {
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const transactions: Ref<Array<Transaction>> = inject('transactions', ref([]))
    const loadNextTransactions = inject('loadNextTransactions', () => {})
    const isMobile = inject('isMobile', ref(false))
    const target = ref(null) as unknown as Ref<HTMLElement>
    useScrollPagination(() => {
      loadNextTransactions()
      dates.length = 0
    }, target)

    const dates: string[] = []
    const getDateValue = (value: string) => {
      const date = moment(value).format('dddd, DD, MMMM, YYYY')
      if (dates.includes(date)) return
      dates.push(date)

      if (moment(value).isSame(moment(), 'day')) return 'Сегодня'
      if (moment(value).isSame(moment().add(-1, 'days'), 'day')) return 'Вчера'
      return date
    }

    return () => <div class='sidebar-default sidebar-wrapper'>
      {!isMobile.value && <>
        <div class='sidebar-cell shadow head'>
          <div class='flex-sb'>
            <h4>Мои расходы</h4>
            <button onClick={() => { onChangeState('settings') }}>
              <img src={require('@/assets/img/settings.svg')} alt='' />
            </button>
          </div>
          <div class='flex link disabled mt-2'>
            <p class='mr-1 desc'>Выгрузить выписку</p>
            <img src={require('@/assets/img/arrow-install.svg')} alt='' style={useColorFilter('#898989')}/>
          </div>
        </div>
        <div class='sidebar-cell'>
          <button class='add-button full-width m-cell' onClick={() => { onChangeState('new-transaction') }}>Добавить расход</button>
          <div class='cell-line'/>
        </div>
      </>}
      <div class='sidebar-cell'>
        { slots.default && slots.default() }
      </div>
      {isMobile.value && <div class='screen sidebar-default-top-row'>
        <button class='add-button full-width m-cell' onClick={() => { onChangeState('new-transaction') }}>Добавить расход</button>
        <button class='img-button'>
          <img src={require('@/assets/img/arrow-install.svg')} alt='' />
        </button>
        <button class='img-button' onClick={() => { onChangeState('settings') }}>
          <img src={require('@/assets/img/settings.svg')} alt='' />
        </button>
      </div>}
      <div class='sidebar-scroll-container scroll-bar'>
        <div class='sidebar-cell'>
          { transactions.value.map((item, idx) =>
            <div>
              <p
                class={{ 'mt-2': idx !== 0, 'mb-1 desc sidebar-transaction-card-date': true }}
              >
                { getDateValue(item.paymentDatetime) }
              </p>
              <TransactionCard
                item={item}
                onSetTransactionId={(id: string) => {
                  props.onSetTransactionId(id)
                  onChangeState('new-transaction')
                }}
              />
            </div>
          )}
          <div ref={target}/>
        </div>
      </div>
    </div>
  }
})
