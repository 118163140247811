import { onUnmounted, Ref, watch } from 'vue'

export const useObserver = (
  callback: () => void,
  trigger: Ref<HTMLElement>
) => {
  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      callback()
    }
  }, {})

  watch(trigger, to => {
    if (to) observer.observe(to)
    else observer.disconnect()
  })

  onUnmounted(() => {
    observer.disconnect()
  })
}
