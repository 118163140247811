import { defineComponent, inject, ref, Ref } from 'vue'
import { TSidebarState } from '../../types'
import Account from './account'
import AccountList from './account-list'

export default defineComponent({
  setup () {
    const sidebarState: Ref<TSidebarState> = inject('sidebarState', ref('default'))
    const onChangeState = inject('onChangeState', (state: TSidebarState) => state)
    const itemId: Ref<string|null> = ref(null)

    const onChangeStateHandler = (state: TSidebarState, id?: string) => {
      if (state === 'settings-accounts-edit' && !id) throw new Error('cant switch to edit screen without id parameter')
      onChangeState(state)
      if (id) itemId.value = id
      else itemId.value = null
    }

    return () => <>
      { sidebarState.value === 'settings-accounts-list' && <AccountList onChangeState={onChangeStateHandler}/> }
      { (sidebarState.value === 'settings-accounts-new' || sidebarState.value === 'settings-accounts-edit') && <Account
        itemId={itemId.value}
        onChangeState={onChangeStateHandler}
      /> }
    </>
  }
})
